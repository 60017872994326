import Helpers from "../../../modules/Core/Helpers/Helpers";
import Main from "../../Api/Main";
import Service from "../Service";

class User extends Service {
  fetchOrder(id, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      Main.user.order.one(account, repository, id).then((r) => {
        if (r.isValid()) {
          resolve(r);
        } else {
          reject(r);
        }
      });
    });
  }

  fetchOrders(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      Main.user.order
        .collection(account, repository, {
          params: {
            orderBy: "created_at",
            orderType: "desc",
          },
        })
        .then((r) => {
          if (r.isValid()) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }
}

export default new User();
