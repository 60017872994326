import Dialog from "../../Dialog";
import templateDefault from "../../../templates/default/dialog/user/order";
import { User } from "../../../Services/Default";

export default class Order extends Dialog {
  template = templateDefault;

  id = "user-order-dialog";

  openDialog(options) {
    super.openDialog(options);

    let { orderId = this.getPage().getQuery("id") } = options || {};

    if (orderId) {
      this.retrieveOrder(orderId);
    } else {
      this.closeDialog();
      this.getPage().redirect("/spot");
    }
  }

  async retrieveOrder(orderId) {
    try {
      const response = await User.fetchOrder(orderId);

      this.setData({
        "default.order": response.getData(),
      });
    } catch (r) {
      this.closeDialog();
      this.getApp().openDialog("login", { dialog: "user-order", orderId });
    }
  }
}
