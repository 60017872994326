import * as React from "react";
import {
  Grid,
  Box,
  Paper,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CircularProgressContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    ".order-list": {
      listStyleType: "none",
      padding: 0,
      margin: theme.spacing(0, 0, 2, 0),
      li: {
        marginBottom: theme.spacing(1),
      },
    },
  },
  "& .MuiAlert-standard": {
    marginBottom: "10px",
  },
}));

const Order = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  "h4, h5": {
    margin: 0,
    padding: 0,
    textAlign: "center",
  },
  ul: {
    listStyleType: "none",
    margin: theme.spacing(0, 0, 2, 0),
    padding: 0,
    marginTop: "15px",
  },
  ".actions": {
    display: "flex",
    justifyContent: "right",
  },
}));

const handleClose = (props) => {
  const { component } = props;
  component.closeDialog();
};

const OrdersList = (props) => {
  const { component } = props;

  return (
    <Grid container spacing={2}>
      {component.getData("default.orders", []).map((order) => {
        return (
          <Grid key={order.id} item lg={4} md={4} sm={6} xs={12}>
            <Order>
              <h4>{order.id}</h4>
              <h5>
                {order.shipping_address ? (
                  <>
                    <i className="fa-duotone fa-moped "></i>{" "}
                    {order.shipping_address.address}
                  </>
                ) : (
                  <>
                    <i className="fa-solid fa-shop"></i> {order.spot.name}
                  </>
                )}
              </h5>
              <ul>
                <li>
                  {component.ucfirst("payment-type")}: {order.paymentType.name}
                </li>
                <li>
                  {component.ucfirst("subtotal")}: {order.totals.items} &euro;
                </li>
                <li>
                  {component.ucfirst("services")}: {order.totals.services}{" "}
                  &euro;
                </li>
                <li>
                  {component.ucfirst("discount")}: {order.totals.discount}{" "}
                  &euro;
                </li>
                <li>
                  {component.ucfirst("grand-total")}: {order.totals.grand}{" "}
                  &euro;
                </li>
                <li>
                  {component.ucfirst("status")}: {order._status}
                </li>
              </ul>
              <Box className="actions">
                <Button
                  type="submit"
                  variant="contained"
                  color="color1"
                  onClick={() => component.showMore(order)}
                >
                  {component.ucfirst("more")}
                </Button>
              </Box>
            </Order>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default function Orders(props) {
  const { component } = props;
  const orders = component.getData("default.orders");

  return (
    <BootstrapDialog
      onClose={() => handleClose(props)}
      aria-labelledby="customized-dialog-title"
      open={component.getData("default.opened", false)}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle className="dialog-title">
        {component.ucfirst("orders")}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(props)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {orders ? (
          orders.length ? (
            <OrdersList {...props} />
          ) : (
            <Alert variant="filled" severity="info">
              {component.ucfirst("no-orders-available")}
            </Alert>
          )
        ) : (
          <CircularProgressContainer>
            <CircularProgress color="color1" />
          </CircularProgressContainer>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
}
