import * as React from "react";
import {
  Container,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Contents = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "64px",
  ".logo": {
    fontSize: "1.2rem",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  ".account-icon": {
    fontSize: "1.2rem",
  },
}));

function showLogin() {
  const pathnames = ["/login", "/register"];
  return pathnames.indexOf(window.location.pathname) === -1;
}

export default function Header1(props) {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openProfile = () => {
    component.getApp().openDialog("profile");
    handleClose();
  };

  const openAddresses = () => {
    component.getApp().openDialog("addresses");
    handleClose();
  };

  const openOrders = () => {
    component.getApp().openDialog("user-orders");
    handleClose();
  };

  const logout = () => {
    component.getPage().logout();
    handleClose();
  };

  return (
    <Container>
      <Contents>
        <Link className="logo" to="/spot">
          {envHelper.get("title")}
        </Link>
        {component.isAuthenticated() ? (
          <Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <i className="account-icon fa-solid fa-user"></i>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={openProfile}>
                {component.ucfirst("profile")}
              </MenuItem>
              <MenuItem onClick={openAddresses}>
                {component.ucfirst("addresses")}
              </MenuItem>
              <MenuItem onClick={openOrders}>
                {component.ucfirst("orders")}
              </MenuItem>
              <Divider />
              <MenuItem onClick={logout}>
                {component.ucfirst("logout")}
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          showLogin() && (
            <Button
              // component={Link}
              // to={`/login?redirect=${createRedirectLink(props)}`}
              variant="empty"
              className="login-btn"
              onClick={() => component.getApp().openDialog("login")}
            >
              {component.ucfirst("login")}
            </Button>
          )
        )}
      </Contents>
    </Container>
  );
}
