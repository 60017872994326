import Dialog from "../Dialog";
import templateDefault from "../../templates/default/dialog/meal";
import { Hook } from "../../Services/Default";

export default class Meal extends Dialog {
  template = templateDefault;

  id = "meal-dialog";

  openDialog(options) {
    super.openDialog(options);
    this.fetchMeal();
  }

  async fetchMeal() {
    const { spot, date, meal } = this.getData("default.options", {});

    if (meal) {
      const mealId = meal._id;

      const response = await Hook.get("spot-date-meal", {
        params: {
          spot,
          date,
          id: mealId,
        },
      });

      if (response.isValid()) {
        const meal = response.getData();

        if (meal) {
          this.prepareMeal(meal);
        }
      }
    }
  }

  prepareMeal(meal) {
    const { item, price, value_prices = {} } = meal;
    const { _id, _values } = item;

    if (_values) {
      const meal = { ..._values, ...{ _id } };

      meal.price_b2c = price ? price : meal.price_b2c;

      Object.keys(value_prices).forEach((slug) => {
        const mealOption = meal.meal_options.find(
          (option) => option.item._values.slug === slug
        );

        if (mealOption) {
          mealOption.price = value_prices[slug];
        }
      });

      const data = { "default.meal": meal };

      if (this.isAdd()) {
        const { meal_options = [] } = meal;

        data["default.selectedOptions"] = meal_options
          .filter((option) => option.selected)
          .map((option) => this.formatOption(option));
      } else {
        data["default.selectedOptions"] = this.getData(
          "default.options.meal.values",
          []
        );
      }

      this.setData(data);
    }
  }

  isAdd() {
    return this.getData("default.options.type", "") === "add";
  }

  getScopes() {
    const scopes = this.getData("default.meal.meal_option_scopes", []).filter(
      ({ active }) => active
    );
    return scopes;
  }

  getScopeOptions(scope) {
    return this.getData("default.meal.meal_options", [])
      .filter((option) => option.item._values.scope === scope)
      .sort((option1, option2) => option1.position - option2.position);
  }

  addToCart() {
    const meal = this.getData("default.meal");
    const values = this.getData("default.selectedOptions", []);

    if (meal) {
      const missing = this.validate();

      if (!missing.length) {
        this.getComponents()
          .findById("menu-meals")
          .forEach((c) => c.addToCart(meal._id, values));

        this.closeDialog();
      } else {
        //
        this.getPage().openMainMessage(
          [this.ucfirst("select"), " - ", missing.shift()].join(" "),
          "error"
        );
      }
    }

    return this;
  }

  validate() {
    const required = this.getData("default.meal.meal_option_scopes", [])
      .filter((scope) => scope.required)
      .map(({ scope }) => scope);
    const selectedOptions = this.getData("default.selectedOptions", [])
      .map(({ scope }) => scope)
      .filter((scope) => required.indexOf(scope) >= 0);

    return required.filter((required) => !selectedOptions.includes(required));
  }

  updateMeal() {
    const meal = this.getData("default.options.meal");
    const values = this.getData("default.selectedOptions", []);
    const date = this.getData("default.options.date");

    this.getPage().updateValues(meal, values, date);
    this.closeDialog();
  }

  selectOption(scope, option, checked) {
    let selectedOptions = this.getData("default.selectedOptions", []);

    if (scope.multiple) {
      if (!checked) {
        selectedOptions = selectedOptions.filter(
          (selectedOption) => selectedOption.slug !== option.item._values.slug
        );
      }
    } else {
      selectedOptions = selectedOptions.filter(
        (selectedOption) => selectedOption.scope !== scope.scope
      );

      checked = true;
    }

    if (checked) {
      selectedOptions.push(this.formatOption(option));
    }

    this.setData({ "default.selectedOptions": selectedOptions });

    return this;
  }

  isOptionSelected(option) {
    return this.getData("default.selectedOptions", []).find(
      (selectedOption) => selectedOption.slug === option
    )
      ? true
      : false;
  }

  formatOption(option) {
    return {
      slug: option.item._values.slug,
      name: option.item._values.name,
      scope: option.item._values.scope,
      price: option.price,
    };
  }

  getPrice(slug, value) {
    return this.getPage().getPrice(slug, value);
  }
}
