import Dialog from "../Dialog";
import templateDefault from "../../templates/default/dialog/login";
import { Auth, Order } from "../../Services/Default";

export default class Login extends Dialog {
  template = templateDefault;

  id = "login-dialog";

  openDialog(options) {
    super.openDialog(options);

    this.loadGoogle();
    this.setData({ "default.login": {} });
  }

  async login(e) {
    if (e) e.preventDefault();
    const { username, password } = this.getData("default.login", {});

    const errors = this.getHelpers("validate").validate(
      { username, password },
      {
        username: [
          {
            rule: "required",
            message: this.ucfirst("required"),
          },
          {
            rule: "email",
            message: this.ucfirst("no-valid-email"),
          },
        ],
        password: [
          {
            rule: "required",
            message: this.ucfirst("required"),
          },
        ],
      }
    );

    this.setData({
      "error.login": errors || {},
      message: {},
    });

    if (!errors) {
      try {
        const response = await Auth.login({ username, password });
        this.afterLogin(response);
      } catch (e) {
        this.getPage().openMainMessage(this.ucfirst(e.getMessage()), "error");
      }
    }
  }

  loadGoogle() {
    const interval = setInterval(() => {
      const googleClientId = this.getHelpers("env").get("google_client_id");
      const element = document.getElementById("google-sign-in-btn");
      const googleSdk = document.getElementById("google-sdk");

      if (googleClientId && element) {
        clearInterval(interval);

        if (googleSdk) {
          this.loadGoogleLogin();
        }

        const onLoad = () => this.loadGoogleLogin();

        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://accounts.google.com/gsi/client";
          js.onload = () => onLoad();
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "google-sdk");
      }
    }, 500);
  }

  loadGoogleLogin() {
    const googleClientId = this.getHelpers("env").get("google_client_id");

    window.google.accounts.id.initialize({
      client_id: googleClientId,
      callback: async (response) => {
        const { email, name } = JSON.parse(
          atob(response.credential.split(".")[1])
        );

        const r = await Auth.loginGoogle({ email, name });

        if (r.isValid()) {
          this.afterLogin(r);
        }
      },
    });

    window.google.accounts.id.renderButton(
      document.getElementById("google-sign-in-btn"),
      { theme: "outline", size: "large" } // customization attributes
    );

    window.google.accounts.id.prompt();
  }

  loginFB() {
    if (window.FB) {
      const options = {
        scope: "public_profile,email",
        auth_type: "rerequest",
        return_scopes: true,
      };

      window.FB.login((response) => {
        if (response.status === "connected") {
          const { accessToken } = response.authResponse;
          const options = { fields: "name,email" };

          window.FB.api("/me", options, async (response) => {
            const fbResponse = response;
            fbResponse.accessToken = accessToken;

            const r = await Auth.loginFB(fbResponse);

            if (r.isValid()) {
              this.afterLogin(r);
            }
          });
        }
      }, options);
    }
  }

  afterLogin(response) {
    const { dialog } = this.getData("default.options", {});
    this.getHelpers("auth").set(response.getData());
    this.getPage().login();
    this.closeDialog();
    Order.delete();

    if (dialog && ["login", "register"].indexOf(dialog) === -1) {
      this.getApp().openDialog(dialog);
    }

    this.getPage().openMainMessage(this.ucfirst("welcome"));
  }

  openRegisterDialog() {
    const { dialog } = this.getData("default.options", {});
    this.closeDialog();
    this.getApp().openDialog("register", { dialog });
  }
}
