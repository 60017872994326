import * as React from "react";
import { Box, Button, styled } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";

const Contents = styled(Box)(({ theme }) => ({
  padding: "20px 0",
  ".order-dates": {
    marginBottom: "40px",
  },
}));

const ContinueButtonContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));

const ContinueButton = styled(Button)(({ theme }) => ({
  fontSize: "1.3rem",
}));

const ContinueButtonContainerFull = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "0",
  right: "0",
  zIndex: "1000",
  width: "100%",
  textAlign: "center",
  backgroundColor: "white",
}));

const ContinueButtonFull = styled(Button)(({ theme }) => ({
  fontSize: "1.3rem",
  width: "100%",
  borderRadius: "0",
}));

export default function Complete(props) {
  const { component } = props;
  const preparing = component.getData("default.preparing", false);

  return (
    <Contents>
      <ContinueButtonContainer sx={{ display: { sm: "block", xs: "none" } }}>
        {preparing ? (
          <CircularProgress />
        ) : (
          <ContinueButton
            variant="contained"
            size="large"
            color="color1"
            disabled={preparing}
            startIcon={<CheckCircleIcon />}
            onClick={() => component.complete()}
          >
            {component.ucfirst("complete")}
          </ContinueButton>
        )}
      </ContinueButtonContainer>
      <ContinueButtonContainerFull
        sx={{ display: { sm: "none", xs: "block" } }}
      >
        {preparing ? (
          <CircularProgress />
        ) : (
          <ContinueButtonFull
            variant="contained"
            size="large"
            color="color1"
            disabled={preparing}
            startIcon={<CheckCircleIcon />}
            onClick={() => component.complete()}
          >
            {component.ucfirst("complete")}
          </ContinueButtonFull>
        )}
      </ContinueButtonContainerFull>
    </Contents>
  );
}
