import * as React from "react";
import {
  Box,
  Paper,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CircularProgressContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    ".order-list": {
      listStyleType: "none",
      padding: 0,
      margin: theme.spacing(0, 0, 2, 0),
      li: {
        marginBottom: theme.spacing(1),
      },
    },
  },
  "& .MuiAlert-standard": {
    marginBottom: "10px",
  },
}));

const handleClose = (props) => {
  const { component } = props;
  component.closeDialog();
};

const StyledOrder = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ".paper": {
    padding: theme.spacing(2),
  },
  ul: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
  },
  ".items": {
    marginTop: theme.spacing(1),
  },
  ".services": {
    marginTop: theme.spacing(1),
  },
  ".dateTotals": {
    marginTop: theme.spacing(1),
  },
  ".dates": {
    marginTop: theme.spacing(1),
  },
  ".dateTitle": {
    textAlign: "center",
  },
  ".item": {
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  ".date": {
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  ".business": {
    marginTop: theme.spacing(1),
  },
  ".coupon": {
    marginTop: theme.spacing(1),
  },
  ".transaction": {
    marginTop: theme.spacing(1),
  },
}));

const OrderInfo = (props) => {
  const { component } = props;
  const { order = {} } = component.getData("default", {});

  order.dates = order.dates.sort((a, b) => {
    const date1 = a.date.replaceAll("-", "") * 1;
    const date2 = b.date.replaceAll("-", "") * 1;

    return date1 - date2;
  });

  return (
    <StyledOrder>
      <ul>
        {order.shipping_address && (
          <li>
            {component.ucfirst("shipping-address")}:{" "}
            {order.shipping_address.address}
          </li>
        )}
        {order.spot && (
          <li>
            {component.ucfirst("pickup-spot")}: {order.spot.name} -{" "}
            {order.spot.address}
          </li>
        )}
        <li>
          {component.ucfirst("payment-type")}: {order.paymentType.name}
        </li>
        <li>
          {component.ucfirst("subtotal")}: {order.totals.items} &euro;
        </li>
        <li>
          {component.ucfirst("services")}: {order.totals.services} &euro;
        </li>
        <li>
          {component.ucfirst("discount")}: {order.totals.discount} &euro;
        </li>
        <li>
          {component.ucfirst("grand-total")}: {order.totals.grand} &euro;
        </li>
        <li>
          {component.ucfirst("status")}: {order._status}
        </li>
        <li>
          {component.ucfirst("notes")}: {order.notes || "-"}
        </li>
        {order.invoice && (
          <li className="business">
            {/* {component.ucfirst("business")}: */}
            <Paper elevation={1} className="paper">
              <ul>
                <li>
                  {component.ucfirst("company-name")}: {order.business.name}
                </li>
                <li>
                  {component.ucfirst("profession")}: {order.business.profession}
                </li>
                <li>
                  {component.ucfirst("address")}: {order.business.address}
                </li>
                <li>
                  {component.ucfirst("city")}: {order.business.city}
                </li>
                <li>
                  {component.ucfirst("tax-office")}: {order.business.tax_office}
                </li>
                <li>
                  {component.ucfirst("tax-number")}: {order.business.vat_number}
                </li>
              </ul>
            </Paper>
          </li>
        )}
        <li className="dates">
          {/* {component.ucfirst("dates")}:{" "} */}
          <Paper elevation={1} className="paper">
            <ul>
              {order.dates.map((orderDate) => {
                let {
                  date,
                  items = [],
                  services = [],
                  totals = [],
                } = orderDate;

                services = services.filter((service) => service.selected);

                return (
                  <li key={`order-date-${date}`} className="date">
                    <Paper elevation={1} className="paper">
                      <Typography variant="h6" className="dateTitle">
                        {date.split("-").reverse().join("-")}
                      </Typography>
                      <ul>
                        <li className="items">
                          <Typography>{component.ucfirst("meals")}:</Typography>
                          <Paper elevation={1} className="paper">
                            <ul>
                              {items.map((orderItem) => {
                                const { slug, name, price, amount } = orderItem;

                                return (
                                  <li
                                    key={`order-items-${order._id}-${slug}`}
                                    className="item"
                                  >
                                    <ul>
                                      <li>
                                        {component.ucfirst("sku")}: {slug}
                                      </li>
                                      <li>
                                        {component.ucfirst("name")}: {name}
                                      </li>
                                      <li>
                                        {component.ucfirst("amount")}: {amount}
                                      </li>
                                      <li>
                                        {component.ucfirst("price")}: {price}{" "}
                                        &euro; {component.ucfirst("per-meal")}
                                      </li>
                                    </ul>
                                  </li>
                                );
                              })}
                            </ul>
                          </Paper>
                        </li>
                        {services.length > 0 && (
                          <li className="services">
                            <Typography>
                              {component.ucfirst("services")}:
                            </Typography>
                            <Paper elevation={1} className="paper">
                              <ul>
                                {services.map((service) => {
                                  const { slug, name, total } = service;

                                  return (
                                    <li
                                      key={`order-items-${order._id}-${slug}`}
                                      className="item"
                                    >
                                      <ul>
                                        <li>
                                          {component.ucfirst("sku")}: {slug}
                                        </li>
                                        <li>
                                          {component.ucfirst("name")}: {name}
                                        </li>
                                        <li>
                                          {component.ucfirst("total")}:{" "}
                                          {total.toFixed(2)} &euro;
                                        </li>
                                      </ul>
                                    </li>
                                  );
                                })}
                              </ul>
                            </Paper>
                          </li>
                        )}
                        <li className="dateTotals">
                          <Typography>
                            {component.ucfirst("totals")}:
                          </Typography>
                          <Paper elevation={1} className="paper">
                            <ul>
                              <li>
                                {component.ucfirst("items")}: {totals.items}{" "}
                                &euro;
                              </li>
                              <li>
                                {component.ucfirst("services")}:{" "}
                                {totals.services} &euro;
                              </li>
                              {totals.discount < 0 && (
                                <li>
                                  {component.ucfirst("discount")}:{" "}
                                  {totals.discount} &euro;
                                </li>
                              )}

                              <li>
                                {component.ucfirst("grand-total")}:{" "}
                                {totals.grand} &euro;
                              </li>
                            </ul>
                          </Paper>
                        </li>
                      </ul>
                    </Paper>
                  </li>
                );
              })}
            </ul>
          </Paper>
        </li>
      </ul>
    </StyledOrder>
  );
};

export default function Order(props) {
  const { component } = props;
  const { order = null } = component.getData("default", {});

  return (
    <BootstrapDialog
      onClose={() => handleClose(props)}
      aria-labelledby="customized-dialog-title"
      open={component.getData("default.opened", false)}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle className="dialog-title">
        {component.ucfirst("order")}: {order && order._id}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(props)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {order ? (
          <OrderInfo {...props} />
        ) : (
          <CircularProgressContainer>
            <CircularProgress color="color1" />
          </CircularProgressContainer>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
}
