import Dialog from "../Dialog";
import templateDefault from "../../templates/default/dialog/addresses";
import { Address, Order } from "../../Services/Default";

export default class Addresses extends Dialog {
  template = templateDefault;

  id = "addresses-dialog";

  openDialog(options) {
    super.openDialog(options);
    this.retrieveAddresses();
  }

  async retrieveAddresses() {
    try {
      const response = await Address.fetchAddresses();
      const addresses = response.getData();

      if (addresses.length) {
        this.setData({
          "default.addresses": addresses,
          "default.selectedAddress": addresses.length ? addresses[0].id : null,
        });
      } else {
        this.getApp().closeDialog("addresses").openDialog("new-address");
      }
    } catch (r) {
      this.closeDialog();
      this.getApp().openDialog("login", { dialog: "addresses" });
    }
  }

  async createOrder() {
    const id = this.getData("default.selectedAddress", null);

    if (id) {
      const response = await Order.updateAddress({ shippingAddressId: id });

      if (response.getData()) {
        window.location.href = "/spot";
      } else {
        this.getPage().openMainMessage(
          this.ucfirst("delivery-not-supported-yet"),
          "error"
        );
      }
    }
  }

  createNewAddress() {
    this.closeDialog();
    this.getApp().openDialog("new-address");
    return this;
  }
}
