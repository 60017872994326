import Dialog from "../Dialog";
import templateDefault from "../../templates/default/dialog/new_address";
import { Address, Order } from "../../Services/Default";

export default class NewAddresses extends Dialog {
  template = templateDefault;

  id = "new-address-dialog";

  openDialog(options) {
    super.openDialog(options);
    this.initAutocomplete();
  }

  getAutocomplete() {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        const input = document.getElementById("address-address");

        if (input) {
          clearInterval(interval);

          const options = {
            types: ["address"],
            componentRistrictions: { country: "gr" },
            fields: ["address_components", "geometry"],
          };

          const autocomplete = new window.google.maps.places.Autocomplete(
            input,
            options
          );

          resolve(autocomplete);
        }
      }, 100);
    });
  }

  async initAutocomplete() {
    const autocomplete = await this.getAutocomplete();

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (place.geometry) {
        let street_number = "";
        let street = "";
        let country = "";
        let state = "";
        let city = "";
        let postal_code = "";

        for (const component of place.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "street_number": {
              street_number = component.long_name;
              break;
            }
            case "route": {
              street = component.short_name;
              break;
            }
            case "postal_code": {
              postal_code = `${component.long_name}${postal_code}`;
              break;
            }
            // case "postal_code_suffix": {
            //   postal_code = `${postal_code}-${component.long_name}`;
            //   break;
            // }
            case "locality":
              city = component.short_name;
              break;
            case "administrative_area_level_1": {
              state = component.long_name;
              break;
            }
            case "country":
              country = component.short_name;
              break;
            default:
              break;
          }
        }

        postal_code = postal_code.split("-").shift();

        if (!street_number) {
          this.getPage().openMainMessage(
            this.ucfirst("street-number-missing"),
            "error"
          );

          this.setData({
            "default.address": {
              address: street,
            },
          });
        } else {
          this.setData({
            "default.address": {
              address: [street, street_number, city, postal_code].join(" "),
              street_number,
              street,
              country,
              state,
              city,
              postal_code: postal_code.replaceAll(" ", ""),
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      }
    });
  }

  async createAddress() {
    const { address = {} } = this.getData("default", {});

    if (this.isValid()) {
      const response = await Address.createAddress(address);

      if (response.isValid()) {
        const { _id } = response.getData();
        this.createOrder(_id);
      }
    }
  }

  async createOrder(id) {
    if (id) {
      const response = await Order.updateAddress({ shippingAddressId: id });

      if (response.getData()) {
        window.location.href = "/spot";
      } else {
        this.getPage().openMainMessage(
          this.ucfirst("delivery-not-supported-yet"),
          "error"
        );
      }
    }
  }

  selectAddress() {
    this.closeDialog();
    this.getApp().openDialog("addresses");
    return this;
  }

  isValid() {
    const { address = {} } = this.getData("default", {});
    return address.street_number ? true : false;
  }
}
