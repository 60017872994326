import Api from "../../modules/Core/Helpers/Api";
// import Date from "../../modules/Core/Helpers/DateTime";
// import Value from "../../modules/Core/Helpers/Value";
// import Dev from "../../modules/Core/Helpers/Dev";
// import Value from "../../modules/Core/Helpers/Value";
import Language from "../../modules/Core/Helpers/Language";

const api = Api.create("totaperaki");
const contentApi = Api.create("content");
const shareApi = Api.create("share");

// function prepareVersions(type, name, options) {
//   options = options || {};
//   const { params = {} } = options;
//   const dataVersions = JSON.parse(Value.getValue("data-versions", "{}"));
//   const version = Dev.getObjectValue(dataVersions, `${type}.${name}`);

//   params.ver = version ? version : Date.getCurrentDate();
//   options.params = params;

//   return options;
// }

function addParam(options, param, value) {
  options = options || {};
  const { params = {} } = options;
  params[param] = value;
  options.params = params;

  return options;
}

const ExportDefault = {
  auth: {
    login: function (account, repository, data, options) {
      // options = addParam(options, "lang", Value.getValue("lang"));
      options = addParam(options, "lang", Language.getLang());

      return api.post(
        `account/${account}/repository/${repository}/auth/login`,
        data,
        options
      );
    },
    loginFB: function (account, repository, data, options) {
      // options = addParam(options, "lang", Value.getValue("lang"));
      options = addParam(options, "lang", Language.getLang());

      return api.post(
        `account/${account}/repository/${repository}/auth/login/fb`,
        data,
        options
      );
    },
    loginGoogle: function (account, repository, data, options) {
      // options = addParam(options, "lang", Value.getValue("lang"));
      options = addParam(options, "lang", Language.getLang());

      return api.post(
        `account/${account}/repository/${repository}/auth/login/google`,
        data,
        options
      );
    },
    register: function (account, repository, data, options) {
      // options = addParam(options, "lang", Value.getValue("lang"));
      options = addParam(options, "lang", Language.getLang());

      return api.post(
        `account/${account}/repository/${repository}/auth/register`,
        data,
        options
      );
    },
    password: {
      request: function (account, repository, options) {
        return api.post(
          `account/${account}/repository/${repository}/auth/password/request`,
          options
        );
      },
      reset: function (account, repository, options) {
        return api.post(
          `account/${account}/repository/${repository}/auth/password/reset`,
          options
        );
      },
    },
    profile: {
      update: function (account, repository, options) {
        return api.put(
          `account/${account}/repository/${repository}/auth/profile/update`,
          options
        );
      },
      retrieve: function (account, repository, options) {
        return api.get(
          `account/${account}/repository/${repository}/auth/profile`,
          options
        );
      },
    },
  },
  address: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/address`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/address/${id}`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/address`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/address/${id}`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/address/${id}`,
        options
      );
    },
  },
  coupon: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/coupon`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/coupon`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/coupon/${id}`,
        options
      );
    },
  },
  user: {
    order: {
      collection: function (account, repository, options) {
        return api.get(
          `account/${account}/repository/${repository}/user/b2c/order`,
          options
        );
      },
      one: function (account, repository, id, options) {
        return api.get(
          `account/${account}/repository/${repository}/user/b2c/order/${id}`,
          options
        );
      },
    },
  },
  order: {
    retrieve: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/order/b2c`,
        options
      );
    },
    action: function (account, repository, action, type, data, options) {
      // options = addParam(options, "lang", Value.getValue("lang"));
      options = addParam(options, "lang", Language.getLang());

      return api.put(
        `account/${account}/repository/${repository}/order/b2c/action/${action}/type/${type}`,
        data,
        options
      );
    },
  },
  /**
   *
   * Hook
   *
   */
  hook: function (account, repository, hook, version, options) {
    // options = prepareVersions("hook", hook, options);

    return shareApi.get(
      `content/account/${account}/repository/${repository}/hook/${hook}/${version}`,
      options
    );
  },
  hookPost: function (account, repository, hook, version, data, options) {
    // options = addParam(options, "lang", Value.getValue("lang"));
    options = addParam(options, "lang", Language.getLang());

    return contentApi.post(
      `account/${account}/repository/${repository}/hook/${hook}/${version}`,
      data,
      options
    );
  },
  /**
   *
   * Shared
   *
   */
  languages: function (account, repository, options) {
    // options = prepareVersions("system", "languages", options);

    return shareApi.get(
      `content/account/${account}/repository/${repository}/languages`,
      options
    );
  },
};

export default ExportDefault;
