import Dialog from "../Dialog";
import templateDefault from "../../templates/default/dialog/register";
import { Auth, Order } from "../../Services/Default";

export default class Register extends Dialog {
  template = templateDefault;

  id = "register-dialog";

  openDialog(options) {
    super.openDialog(options);

    this.setData({ "default.register": {} });
  }

  async register(e) {
    if (e) e.preventDefault();
    const { name, username, password, verify } = this.getData(
      "default.register",
      {}
    );

    if (password !== verify) {
      this.getPage().openMainMessage(
        this.ucfirst("password-mismatch"),
        "error"
      );
      return;
    }

    const errors = this.getHelpers("validate").validate(
      { name, username, password, verify },
      {
        name: [
          {
            rule: "required",
            message: this.ucfirst("required"),
          },
          {
            rule: "range",
            min: 2,
            message: this.ucfirst("register-invalid-name"),
          },
        ],
        username: [
          {
            rule: "required",
            message: this.ucfirst("required"),
          },
          {
            rule: "email",
            message: this.ucfirst("no-valid-email"),
          },
        ],
        password: [
          {
            rule: "required",
            message: this.ucfirst("required"),
          },
        ],
        verify: [
          {
            rule: "required",
            message: this.ucfirst("required"),
          },
        ],
      }
    );

    this.setData({
      "error.register": errors || {},
      message: {},
    });

    if (!errors) {
      try {
        const response = await Auth.register({
          name,
          username,
          password,
          verify,
        });

        const { dialog } = this.getData("default.options", {});
        this.getHelpers("auth").set(response.getData());
        this.getPage().login();
        this.closeDialog();
        Order.delete();

        if (dialog && ["login", "register"].indexOf(dialog) === -1) {
          this.getApp().openDialog(dialog);
        }

        this.getPage().openMainMessage(this.ucfirst("welcome"));
      } catch (e) {
        this.getPage().openMainMessage(this.ucfirst(e.getMessage()), "error");
      }
    }
  }

  openLoginDialog() {
    const { dialog } = this.getData("default.options", {});
    this.closeDialog();
    this.getApp().openDialog("login", { dialog });
  }
}
