import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Pages from "../Components/Pages";
import CssBaseline from "@mui/material/CssBaseline";
import Theme1 from "./default/theme/theme1";
import MainMessage from "../Components/Widget/Message/Message1";
import LoginDialog from "../Components/Dialog/Login";
import RegisterDialog from "../Components/Dialog/Register";
import StartOrderDialog from "../Components/Dialog/StartOrder";
import ProfileDialog from "../Components/Dialog/Profile";
import AddressesDialog from "../Components/Dialog/Addresses";
import SpotsDialog from "../Components/Dialog/Spots";
import NewAddressDialog from "../Components/Dialog/NewAddress";
import UserOrdersDialog from "../Components/Dialog/User/Orders";
import UserOrderDialog from "../Components/Dialog/User/Order";
import MealDialog from "../Components/Dialog/Meal";
import PrivacyDialog from "../Components/Dialog/Privacy";
import TermsOfUserDialog from "../Components/Dialog/TermsOfUser";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <ThemeProvider theme={Theme1}>
        <CssBaseline />
        <LoginDialog />
        <RegisterDialog />
        <StartOrderDialog />
        <ProfileDialog />
        <AddressesDialog />
        <SpotsDialog />
        <NewAddressDialog />
        <UserOrdersDialog />
        <UserOrderDialog />
        <PrivacyDialog />
        <MealDialog />
        <TermsOfUserDialog />
        <MainMessage />
        <Router>
          <Switch>
            <Route exact path="/" component={Pages.Home} />
            <Route exact path="/spot/:spot?" component={Pages.Menu} />
            <Route
              exact
              path="/spot/:spot/date/:date?"
              component={Pages.Menu}
            />
            <Route exact path="/summary" component={Pages.Summary} />
            <Route exact path="/checkout" component={Pages.Checkout} />
            <Route exact path="/viva-success" component={Pages.VivaResult} />
            <Route exact path="/viva-failure" component={Pages.VivaResult} />
            <Route component={Pages.NotFound} />
          </Switch>
        </Router>
      </ThemeProvider>
    )
  );
};

export default ExportDefault;
