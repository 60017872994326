import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(2, 2),
    button: {
      marginTop: "5px",
    },
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0, 2, 2, 2),
    form: {
      marginBottom: theme.spacing(1),
    },
  },
  "& .MuiAlert-standard": {
    marginBottom: "10px",
  },
}));

const OrText = styled(Box)(({ theme }) => ({
  "&": {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    ".line": {
      position: "absolute",
      left: 0,
      border: `1px solid ${theme.palette.color5.main};`,
      width: "100%",
    },
    ".text": {
      position: "absolute",
      fontSize: "1.2rem",
      background: "white",
      padding: "0 10px",
    },
  },
}));

const handleClose = (props) => {
  const { component } = props;
  component.closeDialog();
  // component.getApp().openDialog("addresses");
};

export default function NewAddress(props) {
  const { component } = props;

  return (
    <BootstrapDialog
      onClose={() => handleClose(props)}
      aria-labelledby="customized-dialog-title"
      open={component.getData("default.opened", false)}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle className="dialog-title">
        {component.ucfirst("delivery")}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(props)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <FormControl
            fullWidth
            error={component.dataExists("error.address.address")}
            variant="outlined"
          >
            {/* <InputLabel htmlFor="address-address">
              {component.ucfirst("username")}
            </InputLabel> */}
            <OutlinedInput
              id="address-address"
              value={component.getData("default.address.address", "")}
              onChange={(e) => {
                component.setData({
                  "default.address.address": e.target.value,
                  //   "default.doSubmit": false,
                });
              }}
              //   label={component.ucfirst("address")}
              //   onFocus={(e) => {
              //     component.setData({ "default.doSubmit": false });
              //   }}
            />
            {component
              .getData("error.address.address", [])
              .map(({ rule, message }) => {
                return (
                  <FormHelperText key={`address-address-rule-${rule}`}>
                    {message}
                  </FormHelperText>
                );
              })}
          </FormControl>
        </form>
        <Button
          type="submit"
          variant="contained"
          color="color1"
          size="large"
          fullWidth
          disabled={!component.isValid()}
          onClick={() => component.createAddress()}
        >
          {component.ucfirst("order-now")}
        </Button>
        <OrText>
          <Box className="line"></Box>
          <Box className="text">{component.trans("or")}</Box>
        </OrText>
        <Button
          variant="contained"
          color="color3"
          fullWidth
          size="large"
          onClick={() => component.selectAddress()}
        >
          {component.ucfirst("select-address")}
        </Button>
      </DialogContent>
    </BootstrapDialog>
  );
}
