import Dialog from "../Dialog";
import templateDefault from "../../templates/default/dialog/start_order";

export default class StartOrder extends Dialog {
  template = templateDefault;

  id = "start-order-dialog";

  openDialog(options) {
    super.openDialog(options);
    this.startOrder();
  }

  startOrder() {
    if (!this.isAuthenticated()) {
      this.closeDialog();
      this.getApp().openDialog("login", { dialog: "start-order" });
    }
  }

  openAddressesDialog() {
    this.closeDialog();
    this.getApp().openDialog("addresses");
    return this;
  }

  openSpotsDialog() {
    this.closeDialog();
    this.getApp().openDialog("spots");
    return this;
  }
}
