import Page from "../Page";
import templateDefault from "../../templates/default/page/checkout";
import { Hook, Order, Coupon } from "../../Services/Default";

export default class Checkout extends Page {
  title = "checkout";
  takeawaySpots = ["terna"];

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.scrollToTop();
    this.fetchOrder();
    this.fetchCoupons();
  }

  onAuth({ auth }) {
    if (!auth.isAuthenticated()) {
      this.redirectToSpot();
    }
  }

  async fetchOrder() {
    try {
      const orderResponse = await Order.fetchOrder();
      const order = orderResponse.getData() || {};
      this.setOrder(order, true);
      this.verifyPayment();
    } catch (e) {
      this.redirectToSpot();
    }
  }

  async fetchPaymentTypes(order) {
    try {
      if (order) {
        const { spot } = order;

        if (spot) {
          const response = await Hook.get("spot-payment-types", {
            params: { spot: spot.slug },
          });
          const { _values = {} } = response.getData() || {};
          let { payment_types = [] } = _values;

          payment_types = payment_types.map((paymentType) => {
            return {
              ...paymentType.item._values,
              ...{ id: paymentType.item._id },
            };
          });

          this.setData({ "default.paymentTypes": payment_types });
        }
      }
    } catch (e) {
      this.redirectToSpot();
    }
  }

  setOrder(order, first) {
    const { dates = [] } = order;

    if (!dates.length) {
      this.redirectToSpot();
      return;
    }

    dates.sort((a, b) => {
      return a.date.replaceAll("-", "") * 1 - b.date.replaceAll("-", "") * 1;
    });

    if (first) {
      this.fetchPaymentTypes(order);
    }

    this.setData({ "default.order": order });
    return this;
  }

  redirectToSpot() {
    this.redirect("/spot");
    return this;
  }

  async complete() {
    try {
      const notes = this.getData("default.order.notes", "");
      const address = this.getData("default.order.shipping_address", "");

      if (this.validateAddress()) {
        await Order.prepare({ notes, address });
        const { slug = "" } = this.getData("default.order.paymentType", {});
        switch (slug) {
          case "epay-credit": {
            await this.completeCredit();
            break;
          }
          case "viva-credit": {
            await this.completeViva();
            break;
          }
          default: {
            await this.completeDefault();
          }
        }
      }
    } catch (e) {
      console.log(e);
      // this.redirectToSpot();
    }
  }

  // async completeCredit() {
  //   this.setData({ "default.preparing": true });

  //   await Order.preparePayment();

  //   // const { redirectUrl = "" } = Order.getData("order.paymentType", {}) || {};

  //   // if (redirectUrl) {
  //   // this.getPage().redirect(redirectUrl);
  //   // }

  //   this.setData({ "default.preparing": false });

  //   return this;
  // }

  async completeViva() {
    this.setData({ "default.preparing": true });

    await Order.preparePayment();

    const order = Order.getOrder();

    if (order) {
      const { paymentType } = order;
      const { redirectUrl = "" } = paymentType;

      if (redirectUrl) {
        this.getPage().redirect(redirectUrl);
      }
    }

    this.setData({ "default.preparing": false });

    return this;
  }

  async completeDefault() {
    this.setData({ "default.preparing": true });
    await Order.place();
    this.getPage().openMainMessage(this.ucfirst("order-placed"));
    this.redirect("/spot");
    this.setData({ "default.preparing": false });

    return this;
  }

  verifyPayment() {
    const { vivaerror } = this.getPage().getQuery() || {};

    if (vivaerror) {
      this.getPage().openMainMessage(this.ucfirst("viva-error"), "error");
    }

    return this;
  }

  skipValidateAddress() {
    const { spot } = this.getOrder();

    return (
      this.getOrderType() === "takeaway" ||
      this.takeawaySpots.indexOf(spot.slug) >= 0
    );
  }

  validateAddress() {
    if (this.skipValidateAddress()) return true;

    const address = this.getData("default.order.shipping_address", {}) || {};
    const fields = ["phone", "bell", "floor"];
    const error = [];

    fields.forEach((field) => {
      if (!address[field]) {
        error.push(field);
      }
    });

    this.setData({ "error.shipping_address": error });

    const valid = error.length === 0;

    if (!valid) {
      this.getPage().openMainMessage(
        this.ucfirst("address-fields-required"),
        "error"
      );

      this.scrollToTop();
    }

    return valid;
  }

  getOrderType() {
    return this.getData("default.order.order_type");
  }

  getOrder() {
    return this.getData("default.order");
  }

  async updatePaymentType(id) {
    try {
      const response = await Order.updatePaymentType({
        paymentTypeId: id,
      });
      const order = response.getData() || {};
      this.setOrder(order);
    } catch (e) {
      this.redirectToSpot();
    }

    return this;
  }

  async fetchCoupons() {
    try {
      const repsonse = await Coupon.fetchCoupons();
      const coupons = repsonse.getData() || [];
      this.setData({ "default.coupons": coupons });
    } catch (r) {
      this.redirectToSpot();
    }
  }

  async addCoupon() {
    const newCoupon = this.getData("default.newCoupon");

    try {
      const response = await Coupon.addCoupon(newCoupon);
      const couponId = response.getData();

      if (couponId) {
        this.updateCoupon(couponId);
      }
    } catch (r) {
      this.getPage().openMainMessage(this.ucfirst(r.getMessage()), "error");
    }
  }

  async updateCoupon(id) {
    try {
      const response = await Order.updateCoupon({
        couponId: id,
      });
      const order = response.getData() || {};
      this.setOrder(order);
    } catch (r) {
      this.getPage().openMainMessage(this.ucfirst(r.getMessage()), "error");
    }
  }

  async releaseCoupon() {
    try {
      const response = await Order.releaseCoupon();
      const order = response.getData() || {};
      this.setOrder(order);
      this.fetchCoupons();
    } catch (r) {
      this.getPage().openMainMessage(this.ucfirst(r.getMessage()), "error");
    }
  }
}
