import Dialog from "../../Dialog";
import templateDefault from "../../../templates/default/dialog/user/orders";
import { User } from "../../../Services/Default";

export default class Orders extends Dialog {
  template = templateDefault;

  id = "user-orders-dialog";

  openDialog(options) {
    super.openDialog(options);
    this.retrieveOrders();
  }

  async retrieveOrders() {
    try {
      const response = await User.fetchOrders();
      const { rows = [] } = response.getData();

      this.setData({
        "default.orders": rows,
      });
    } catch (r) {
      this.closeDialog();
      this.getApp().openDialog("login", { dialog: "orders" });
    }
  }

  showMore(order) {
    const { id } = order;
    const { pathname } = document.location;

    this.getPage().redirect(`${pathname}?dialog=user-order&id=${id}`);
    this.getApp().openDialog("user-order", { orderId: id });
  }
}
