import * as React from "react";
import { Paper, Grid, Box, TextField, styled } from "@mui/material";

const Contents = styled(Box)(({ theme }) => ({
  padding: "20px 0",
  ".address-info": {
    display: "flex",
    marginBottom: "20px",
    i: {
      fontSize: "1.5rem",
      marginRight: "10px",
    },
    ".address": {
      cursor: "pointer",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textDecoration: "none",
      color: theme.palette.color1.main,
    },
  },
}));

const AddressContainer = styled(Paper)(({ theme }) => ({
  padding: "20px",
}));

export default function Address(props) {
  const { component } = props;
  const orderType = component.getPage().getOrderType();
  const isDelivery = orderType === "delivery";

  return (
    <Contents>
      <Grid justifyContent="center" container spacing={2}>
        <Grid item md={9} sm={12} xs={12}>
          <AddressContainer>
            <Box
              className="address-info"
              // onClick={() => component.getApp().openDialog("addresses")}
            >
              <Box>
                {orderType ? (
                  isDelivery ? (
                    <i className="fa-duotone fa-moped "></i>
                  ) : (
                    <i className="fa-solid fa-shop"></i>
                  )
                ) : (
                  <i className="fa-solid fa-sort-down"></i>
                )}
              </Box>
              <Box>
                <span>{component.ucfirst(orderType)} - </span>
                {isDelivery ? (
                  component.getData(
                    "default.order.shipping_address.address",
                    ""
                  )
                ) : (
                  <a
                    className="address"
                    href={`https://maps.google.com/?q=${component.getData(
                      "default.order.spot.address",
                      ""
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {[
                      component.getData("default.order.spot.name", ""),
                      component.getData("default.order.spot.address", ""),
                    ].join(" - ")}
                  </a>
                )}
              </Box>
            </Box>
            <form onSubmit={(e) => e.preventDefault()}>
              {isDelivery && !component.skipValidateAddress() ? (
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      error={
                        component
                          .getData("error.shipping_address", [])
                          .find((field) => field === "floor")
                          ? true
                          : false
                      }
                      label={component.ucfirst("address-floor")}
                      variant="outlined"
                      fullWidth
                      disabled={!isDelivery}
                      value={
                        component.getData(
                          "default.order.shipping_address.floor",
                          ""
                        ) || ""
                      }
                      onChange={(e) =>
                        component.setData({
                          "default.order.shipping_address.floor":
                            e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={
                        component
                          .getData("error.shipping_address", [])
                          .find((field) => field === "bell")
                          ? true
                          : false
                      }
                      label={component.ucfirst("address-bell")}
                      variant="outlined"
                      fullWidth
                      disabled={!isDelivery}
                      value={
                        component.getData(
                          "default.order.shipping_address.bell",
                          ""
                        ) || ""
                      }
                      onChange={(e) =>
                        component.setData({
                          "default.order.shipping_address.bell": e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={
                        component
                          .getData("error.shipping_address", [])
                          .find((field) => field === "phone")
                          ? true
                          : false
                      }
                      label={component.ucfirst("address-phone")}
                      variant="outlined"
                      fullWidth
                      disabled={!isDelivery}
                      value={
                        component.getData(
                          "default.order.shipping_address.phone",
                          ""
                        ) || ""
                      }
                      onChange={(e) =>
                        component.setData({
                          "default.order.shipping_address.phone":
                            e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={component.ucfirst("notes")}
                      variant="outlined"
                      fullWidth
                      // multiline
                      // rows={5}
                      value={component.getData("default.order.notes", "") || ""}
                      onChange={(e) =>
                        component.setData({
                          "default.order.notes": e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={component.ucfirst("notes")}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      value={component.getData("default.order.notes", "") || ""}
                      onChange={(e) =>
                        component.setData({
                          "default.order.notes": e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </form>
          </AddressContainer>
        </Grid>
      </Grid>
    </Contents>
  );
}
