import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Radio from "@mui/material/Radio";
// import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography, Paper, Grid, Checkbox, Button } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //   "& .MuiDialogTitle-root": {
  //     padding: theme.spacing(2, 2),
  //     button: {
  //       marginTop: "5px",
  //     },
  //   },
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(0, 2, 2, 2),
    padding: theme.spacing(0),
    // sx={{ bgcolor: "color2.main" }}
    backgroundColor: theme.palette.color2.main,
  },
  // "& .MuiDialogActions-root": {
  //   padding: theme.spacing(1, 2, 1),
  // },
  "& .MuiAlert-standard": {
    marginBottom: "10px",
  },
}));

const Meal = styled(Box)(({ theme }) => ({
  ".image-container": {
    position: "relative",
    borderRadius: "5px 5px 0 0",
    // marginBottom: "5px",
    // border: "1px solid red",
    ".image-loader": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    ".image": {
      position: "absolute",
      maxWidth: "100%",
      borderRadius: "5px",
    },
    // ".amount": {
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   height: "100%",
    //   width: "50%",
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   fontSize: "4rem",
    //   backgroundColor: theme.palette.color1.main,
    //   color: "white",
    //   opacity: 0.8,
    //   borderRadius: "5px 0 0 5px",
    // },
    // ".out-of-stock": {
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   height: "100%",
    //   width: "100%",
    //   position: "absolute",
    //   top: 0,
    //   left: 0,
    //   fontSize: "2.0rem",
    //   backgroundColor: "white",
    //   color: theme.palette.color3.main,
    //   opacity: 0.7,
    //   borderRadius: "5px 0 0 5px",
    //   ".text": {
    //     borderBottom: `1px solid ${theme.palette.color3.main}`,
    //   },
    // },
  },
  // ".description": {
  //   height: "53px",
  //   overflow: "hidden",
  // },
  // ".actions": {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   textAlign: "right",
  //   height: "50px",
  // },
  ".MuiFormControlLabel-label": {
    fontSize: "0.8rem",
  },
  // ".add-btn, .remove-btn": {
  //   textDecoration: "capitalize",
  // },
  // ".price": {
  //   fontSize: "1.3rem",
  //   fontWeight: "bold",
  // },
}));

const handleClose = (props) => {
  const { component } = props;
  component.closeDialog();
};

export default function MealDialog(props) {
  const { component } = props;
  const [imageLoaded, setImageLoaded] = useState(false);
  const filesUrl = component.getHelpers("env").get("files_url");
  const meal = component.getData("default.meal");
  const { slug = "", name = "", price_b2c } = meal || {};
  const imageContainerId = `dialog-meal-image-container`;
  const scopes = component.getScopes();
  const isAdd = component.isAdd();

  useEffect(() => {
    let resizeEvent = null;

    if (meal) {
      resizeEvent = () => {
        const imageContainer = document.getElementById(imageContainerId);

        if (imageContainer) {
          imageContainer.style.height = imageContainer.offsetWidth + "px";
        }
      };

      window.addEventListener("resize", resizeEvent);

      const imageInterval = setInterval(() => {
        const imageContainer = document.getElementById(imageContainerId);

        if (imageContainer) {
          clearInterval(imageInterval);
          resizeEvent();
        }
      }, 100);
    }

    return () => {
      if (resizeEvent) {
        window.removeEventListener("resize", resizeEvent, false);
      }
    };
  }, [meal, imageContainerId]);

  return (
    meal && (
      <BootstrapDialog
        onClose={() => handleClose(props)}
        aria-labelledby="customized-dialog-title"
        open={component.getData("default.opened", false)}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={() => handleClose(props)}
            sx={{
              position: "absolute",
              zIndex: 10,
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Meal>
            <Box id={imageContainerId} className="image-container">
              <img
                className="image"
                alt={name}
                src={[filesUrl, `/totaperaki/meals/${slug}.jpg`].join("")}
                onLoad={() => setImageLoaded(true)}
                onError={(e) => {
                  e.target.src = "/images/meal-not-found.png";
                }}
              />
              {/* {amount > 0 && <Box className="amount">{amount}</Box>} */}
              {!imageLoaded && (
                <Skeleton className="image-loader" variant="rectangular" />
              )}
            </Box>
            <Paper
              sx={{
                p: 2,
                fontWeight: "bold",
                textTransform: "capitalize",
                mb: 2,
                borderRadius: 0,
              }}
              elevation={2}
            >
              <Box sx={{ mb: 1 }}>{name}</Box>
              <Box>{price_b2c.toFixed(2)} &euro;</Box>
            </Paper>
            <Box sx={{ p: 1 }}>
              {scopes.map((scope) => {
                const options = component.getScopeOptions(scope.scope);

                return (
                  options.length > 0 && (
                    <Box
                      key={`dialog-meal-options-scope-${slug}-${scope.scope}`}
                      sx={{ p: 1, fontWeight: "bold" }}
                    >
                      <Box sx={{ marginBottom: "5px" }}>
                        {component.ucfirst("select")}&nbsp;
                        {component.ucfirst(scope.scope)}&nbsp;
                        {scope.required && (
                          <Typography
                            component="span"
                            sx={{ color: "color4.main" }}
                          >
                            *
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Grid container spacing={2}>
                          {options.map((option) => {
                            const { item, price = 0 } = option;
                            const { _values } = item;

                            return (
                              <Grid
                                key={`dialog-meal-options-scope-${slug}-${scope.scope}-${option}-${_values.slug}`}
                                item
                                xs={6}
                              >
                                <Paper
                                  sx={{
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    paddingLeft: "15px",
                                  }}
                                  elevation={1}
                                >
                                  {scope.checkbox ? (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          color="color1"
                                          checked={component.isOptionSelected(
                                            _values.slug
                                          )}
                                        />
                                      }
                                      label={[
                                        _values.name,
                                        "-",
                                        `${price.toFixed(2)}€`,
                                      ].join(" ")}
                                      onChange={(e) => {
                                        component.selectOption(
                                          scope,
                                          option,
                                          e.target.checked
                                        );
                                      }}
                                      sx={{ fontSize: "10px" }}
                                    />
                                  ) : (
                                    <FormControlLabel
                                      value={_values.slug}
                                      control={
                                        <Radio
                                          checked={component.isOptionSelected(
                                            _values.slug
                                          )}
                                        />
                                      }
                                      label={[
                                        _values.name,
                                        "-",
                                        `${price.toFixed(2)}€`,
                                      ].join(" ")}
                                      onChange={(e) => {
                                        component.selectOption(
                                          scope,
                                          option,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  )}
                                </Paper>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    </Box>
                  )
                );
              })}
            </Box>
          </Meal>
        </DialogContent>
        <DialogActions>
          {isAdd ? (
            <Button
              color="color1"
              size="large"
              variant="contained"
              fullWidth
              onClick={() => component.addToCart()}
            >
              {component.ucfirst("add")}
            </Button>
          ) : (
            <Button
              color="color1"
              size="large"
              variant="contained"
              fullWidth
              onClick={() => component.updateMeal()}
            >
              {component.ucfirst("save")}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    )
  );
}
