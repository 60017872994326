import CorePage from "../../modules/Core/Components/Page";

export default class Page extends CorePage {
  onLoad(data) {
    super.onLoad(data);
    this.openDialog();
  }

  openDialog() {
    const name = this.getQuery("dialog");

    if (name) {
      this.getApp().openDialog(name);
    }
  }

  openMainMessage(message, severity) {
    this.getComponents()
      .findById("main-message")
      .forEach((c) => c.open(message, severity || "success"));

    return this;
  }
}
