import { useEffect, useState } from "react";
import { Button, Box, Skeleton, styled } from "@mui/material";

const Meal = styled(Box)(({ theme }) => ({
  ".image-container": {
    position: "relative",
    marginBottom: "5px",
    ".image-loader": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    ".image": {
      position: "absolute",
      maxWidth: "100%",
      borderRadius: "5px",
    },
    ".amount": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "50%",
      position: "absolute",
      top: 0,
      left: 0,
      fontSize: "4rem",
      backgroundColor: theme.palette.color1.main,
      color: "white",
      opacity: 0.8,
      borderRadius: "5px 0 0 5px",
    },
    ".out-of-stock": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      fontSize: "2.0rem",
      backgroundColor: "white",
      color: theme.palette.color3.main,
      opacity: 0.7,
      borderRadius: "5px 0 0 5px",
      ".text": {
        borderBottom: `1px solid ${theme.palette.color3.main}`,
      },
    },
  },
  ".description": {
    height: "53px",
    overflow: "hidden",
  },
  ".actions": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "right",
    height: "50px",
  },
  ".add-btn, .remove-btn": {
    textDecoration: "capitalize",
  },
  ".price": {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
}));

export default function Meals(props) {
  const { component, meal = {}, mealType, index } = props;
  const { _id, slug, name, price_b2c, meal_options = [], active } = meal;
  const [imageLoaded, setImageLoaded] = useState(false);

  const amount = component.getMealAmount(_id);
  const imageContainerId = `meal-image-container-${mealType.slug}-${_id}-${index}`;
  const outOfStock = amount === 0 && !active;
  const closed = amount === 0 && !component.isDateOpened();
  const filesUrl = component.getHelpers("env").get("files_url");

  useEffect(() => {
    const resizeEvent = () => {
      const imageContainer = document.querySelector(`#${imageContainerId}`);

      if (imageContainer) {
        imageContainer.style.height = imageContainer.offsetWidth + "px";
      }
    };

    window.addEventListener("resize", resizeEvent);

    resizeEvent();

    return () => {
      window.removeEventListener("resize", resizeEvent, false);
    };
  }, [imageContainerId]);

  return (
    <Meal>
      <Box>
        <Box id={imageContainerId} className="image-container">
          <img
            className="image"
            alt={name}
            src={[filesUrl, `/totaperaki/meals/${slug}.jpg`].join("")}
            onLoad={() => {
              setImageLoaded(true);
            }}
            onError={(e) => {
              e.target.src = "/images/meal-not-found.png";
            }}
          />
          {amount > 0 && <Box className="amount">{amount}</Box>}
          {outOfStock && (
            <Box className="out-of-stock">
              <Box className="text">{component.ucfirst("out-of-stock")}</Box>
            </Box>
          )}
          {!imageLoaded && (
            <Skeleton className="image-loader" variant="rectangular" />
          )}
        </Box>
        <Box className="description">{name}</Box>
        <Box className="actions">
          <span className="price">
            {price_b2c && price_b2c.toFixed(2)} &euro;
          </span>
          {!closed && (
            <div>
              {!outOfStock && component.canRemoveCart(_id) && (
                <Button
                  className="remove-btn"
                  variant="text"
                  color="color1"
                  size="large"
                  onClick={() => component.removeFromCart(_id)}
                >
                  {component.ucfirst("remove")}
                </Button>
              )}
              {!outOfStock && (
                <Button
                  className="add-btn"
                  variant="contained"
                  color="color1"
                  size="large"
                  onClick={() => {
                    if (meal_options.length) {
                      component.openMealDialog(meal);
                    } else {
                      component.addToCart(_id);
                    }
                  }}
                >
                  {component.ucfirst("add")}
                </Button>
              )}
            </div>
          )}
        </Box>
      </Box>
    </Meal>
  );
}
