import Page from "../../Page";
import templateDefault from "../../../templates/default/page/viva/result";
import { Order } from "../../../Services/Default";

export default class VivaResult extends Page {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    if (window.location.pathname === "/viva-success") {
      this.verifyPayment();
    } else {
      this.redirect("/checkout?vivaerror=1");
    }
  }

  async verifyPayment() {
    try {
      await Order.fetchOrder();
      await Order.verifyPayment(this.getPage().getQuery());

      const order = Order.getOrder();

      if (order) {
        const { placed } = order;

        if (placed) {
          Order.delete();
          this.openMainMessage(this.ucfirst("order-placed"));
          this.redirect("/spot");
        }
      }
    } catch (e) {
      //
    }
  }
}
