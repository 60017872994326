import Dialog from "../Dialog";
import templateDefault from "../../templates/default/dialog/spots";
import { Hook, Order } from "../../Services/Default";

export default class Spots extends Dialog {
  template = templateDefault;

  id = "spots-dialog";

  openDialog(options) {
    if (!this.isAuthenticated()) {
      this.closeDialog();
      this.getApp().openDialog("login", { dialog: "spots" });
    } else {
      super.openDialog(options);
      this.retrieveSpots();
    }
  }

  startOrder() {}

  async retrieveSpots() {
    const response = await Hook.get("spots");

    if (response.isValid()) {
      const spots = response.getData();
      this.setData({
        "default.spots": spots,
        "default.selectedSpot": spots.length ? spots[0]._id : null,
      });
    }
  }

  async createOrder() {
    const id = this.getData("default.selectedSpot", null);

    if (id) {
      await Order.updateSpot({ spotId: id });
      window.location.href = "/spot";
    }
  }
}
