import { Component } from "../../modules/Core/Component";

export default class Dialog extends Component {
  widget = true;

  openDialog(options) {
    this.setData({ "default.opened": true, "default.options": options || {} });
  }

  closeDialog() {
    this.setData({ default: { opened: false, options: {} } });
  }

  isOpened() {
    return this.getData("default.opened", false);
  }

  onClose() {
    this.closeDialog();
  }
}
