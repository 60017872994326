import App from "../../modules/Core/Components/App";
import template from "../templates/app";
import { Order } from "../Services/Default";

export default class MainApp extends App {
  template = template;

  onAuth({ auth }) {
    if (!auth.isAuthenticated()) {
      Order.delete();
    }
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    this.installFacebook().installGoogleMaps();
  }

  getOnResizeResolusion(width) {
    if (width < 500) {
      return "mobile";
    }

    if (width >= 500) {
      return "default";
    }
  }

  openDialog(name, options) {
    const component = this.getComponents().findById(`${name}-dialog`).first();

    if (component && !component.isOpened()) {
      component.openDialog(options);
    }

    return this;
  }

  closeDialog(name) {
    const component = this.getComponents().findById(`${name}-dialog`).first();

    if (component) {
      component.closeDialog();
    }

    return this;
  }

  installFacebook() {
    const fbAppId = this.getHelpers("value").getValue("FB_APPID");

    if (fbAppId) {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: fbAppId,
          cookie: true,
          xfbml: true,
          version: "v17.0",
        });

        window.FB.AppEvents.logPageView();
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }

    return this;
  }

  installGoogleMaps() {
    const apiKey = this.getHelpers("value").getValue("GMAPS_API_KEY");

    if (apiKey) {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&region=GR&language=EL`;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "gmaps-api");
    }
    return this;
  }

  formatDate(date) {
    const dates = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

    date = new Date(date);
    return [dates[date.getDay()], date.getDate()];
  }
}
