import * as React from "react";
import {
  Paper,
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Button,
  styled,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const Contents = styled(Box)(({ theme }) => ({
  padding: "20px 0",
  ".order-dates": {
    marginBottom: "40px",
  },
}));

const OrderDate = styled(Paper)(({ theme }) => ({
  padding: "20px",
  ".date-title": {
    textAlign: "center",
    marginBottom: "20px",
    textTransform: "capitalize",
  },
  ".order-meal": {
    marginBottom: "15px",
  },
  ".order-meal.editable .order-meal-name": {
    color: theme.palette.color1.main,
  },
  ".order-meal.editable": {
    cursor: "pointer",
  },
  ".order-meal:last-child": {
    marginBottom: "0",
  },
  ".order-meal-image": {
    // maxWidth: "100%",
    width: "100px",
  },
  ".order-meal-name": {
    padding: "0 10px",
    maxHeight: "50px",
    overflow: "hidden",
    ".order-meal-edit-btn": {
      color: theme.palette.color1.main,
      ":hover": {
        cursor: "pointer",
      },
    },
  },
  ".order-meal-values": {
    padding: "0 10px",
    fontWeight: "bold",
    fontSize: "0.7rem",
    ul: {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
  },
  ".order-meal-price": {
    padding: "0 10px",
    fontWeight: "bold",
  },
  ".totals": {
    padding: "10px 0",
    ".total": {
      display: "flex",
      justifyContent: "space-between",
      fontWeight: "bold",
    },
  },
}));

const ContinueButtonContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));

const ContinueButton = styled(Button)(({ theme }) => ({
  fontSize: "1.3rem",
}));

const ContinueButtonContainerFull = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "0",
  right: "0",
  zIndex: "1000",
  width: "100%",
}));

const ContinueButtonFull = styled(Button)(({ theme }) => ({
  fontSize: "1.3rem",
  width: "100%",
  borderRadius: "0",
}));

export default function Dates(props) {
  const { component } = props;
  const order = component.getData("default.order", {});
  const { spot, dates = [] } = order;
  // const envHelper = component.getHelpers("env");
  // const account = envHelper.getDefaultAccount();
  // const repository = envHelper.getDefaultRepository();
  const filesUrl = component.getHelpers("env").get("files_url");

  return (
    <Contents>
      <Grid
        className="order-dates"
        justifyContent="center"
        container
        spacing={2}
      >
        {dates.map((orderDate) => {
          const {
            date,
            items = [],
            services = [],
            hours = [],
            hour = null,
            totals,
          } = orderDate;

          return (
            <Grid key={`order-dates-${date}`} item md={9} sm={12} xs={12}>
              <OrderDate>
                <Typography className="date-title" variant="h6" gutterBottom>
                  {component
                    .getApp()
                    .formatDate(date)
                    .map((value, index) =>
                      index === 0 ? component.ucfirst(value) : value
                    )
                    .join(" - ")}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <FormControl fullWidth size="small" error={!hour}>
                    {/* <InputLabel>
                      {component.ucfirst("select-delivery-time")}
                    </InputLabel> */}
                    <Select
                      value={hour ? (hour.now ? "now" : hour.from) : ""}
                      // label={component.ucfirst("select-delivery-time")}
                      displayEmpty
                      onChange={(event) =>
                        component.updateHour(date, event.target.value)
                      }
                      sx={{ fontSize: "0.9rem" }}
                    >
                      <MenuItem value="">
                        <em>{component.ucfirst("select-delivery-time")}</em>
                      </MenuItem>
                      {component.filterHours(hours).map((hour, index) => {
                        const value = hour.now ? "now" : hour.from;
                        const label = hour.now
                          ? component.ucfirst("i-want-it-now")
                          : `${hour.from} - ${hour.to}`;

                        return (
                          <MenuItem key={`${date}-hour-${index}`} value={value}>
                            {label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  {items.map((orderMeal) => {
                    const {
                      _id,
                      instance,
                      values = [],
                      slug,
                      name,
                      price,
                      amount,
                    } = orderMeal;

                    const mealKey = `order-dates-${date}-meal-${_id}-${
                      instance || "default"
                    }`;

                    return (
                      <Grid
                        className={`order-meal ${
                          values.length > 0 ? "editable" : ""
                        }`}
                        key={mealKey}
                        justifyContent="center"
                        container
                        onClick={() => {
                          if (values.length > 0) {
                            component.getApp().openDialog("meal", {
                              spot: spot.slug,
                              date,
                              meal: orderMeal,
                            });
                          }
                        }}
                      >
                        <Grid item lg="auto" md="auto" xs="auto">
                          <img
                            className="order-meal-image"
                            alt={name}
                            src={[
                              filesUrl,
                              `/totaperaki/meals/${slug}.jpg`,
                            ].join("")}
                            onError={(e) => {
                              e.target.src = "/images/meal-not-found.png";
                            }}
                          />
                        </Grid>
                        <Grid item lg md xs>
                          <Box
                            className="order-meal-name"
                            sx={{ padding: "0 10px" }}
                          >
                            {name}
                            {/* {values.length > 0 && (
                              <span>
                                &nbsp;-&nbsp;
                                <span
                                  className="order-meal-edit-btn"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    component.getApp().openDialog("meal", {
                                      meal: orderMeal,
                                      date,
                                    });
                                  }}
                                >
                                  {component.trans("edit")}
                                </span>
                              </span>
                            )} */}
                          </Box>
                          <Box className="order-meal-values">
                            <ul>
                              {values.map((value) => {
                                const { slug, price = 0 } = value;

                                return (
                                  <li key={`${mealKey}-value-${slug}`}>
                                    {component.ucfirst(slug)} -{" "}
                                    {price.toFixed(2)} &euro;
                                  </li>
                                );
                              })}
                            </ul>
                          </Box>
                          <Box className="order-meal-price">
                            {price.toFixed(2)} &euro;
                            {/* {price.toFixed(2)} &euro; */}
                          </Box>
                        </Grid>
                        <Grid item lg="auto" md="auto" xs="auto">
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ width: "65px" }}
                          >
                            {/* <InputLabel>{component.ucfirst("qty")}</InputLabel> */}
                            <Select
                              value={amount}
                              // label={component.ucfirst("qty")}
                              displayEmpty
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) =>
                                component.updateItem(
                                  _id,
                                  instance,
                                  date,
                                  e.target.value
                                )
                              }
                              sx={{ fontSize: "0.9rem" }}
                            >
                              {[...new Array(101)].map((_, index) => {
                                const value = index;

                                return (
                                  <MenuItem
                                    key={`${mealKey}-option-${index}`}
                                    value={value}
                                  >
                                    {value}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
                <Box className="totals">
                  <Typography className="total" gutterBottom>
                    <span>{component.ucfirst("meals-total")}</span>
                    <span>{totals.items.toFixed(2)} &euro;</span>
                  </Typography>
                  {services
                    .filter(({ total }) => total)
                    .map((service) => {
                      const { _id, slug, total } = service;
                      return (
                        <Typography
                          key={`order-date-service-${_id}`}
                          className="total"
                          gutterBottom
                        >
                          <span>{component.ucfirst(slug)}</span>
                          <span>{total.toFixed(2)} &euro;</span>
                        </Typography>
                      );
                    })}
                  {totals.discount !== 0 && (
                    <Typography className="total" gutterBottom>
                      <span>{component.ucfirst("discount")}</span>
                      <span>{totals.discount.toFixed(2)} &euro;</span>
                    </Typography>
                  )}
                  <Typography className="total" gutterBottom>
                    <span>{component.ucfirst("grand-total")}</span>
                    <span>{totals.grand.toFixed(2)} &euro;</span>
                  </Typography>
                </Box>
              </OrderDate>
            </Grid>
          );
        })}
      </Grid>
      <ContinueButtonContainer sx={{ display: { sm: "block", xs: "none" } }}>
        <ContinueButton
          // component={Link}
          // to="/checkout"
          variant="contained"
          size="large"
          color="color1"
          startIcon={<ShoppingCartIcon />}
          onClick={() => component.continue()}
        >
          {component.ucfirst("continue")}
        </ContinueButton>
      </ContinueButtonContainer>
      <ContinueButtonContainerFull
        sx={{ display: { sm: "none", xs: "block" } }}
      >
        <ContinueButtonFull
          // component={Link}
          // to="/checkout"
          variant="contained"
          size="large"
          color="color1"
          startIcon={<ShoppingCartIcon />}
          onClick={() => component.continue()}
        >
          {component.ucfirst("continue")}
        </ContinueButtonFull>
      </ContinueButtonContainerFull>
    </Contents>
  );
}
