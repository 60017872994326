import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    paddingLeft: theme.spacing(2),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
    ".MuiFormControl-root": {
      marginBottom: theme.spacing(2),
    },
  },
}));

const handleClose = (props) => {
  const { component } = props;
  component.closeDialog();
};

export default function Register(props) {
  const { component } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <BootstrapDialog
      onClose={() => handleClose(props)}
      aria-labelledby="customized-dialog-title"
      open={component.getData("default.opened", false)}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle className="dialog-title">
        {component.ucfirst("register")}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(props)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => component.register(e)}>
          <FormControl
            fullWidth
            error={component.dataExists("error.register.name")}
            variant="outlined"
          >
            <InputLabel htmlFor="auth-name">
              {component.ucfirst("name")}
            </InputLabel>
            <OutlinedInput
              id="auth-name"
              value={component.getData("default.register.name", "")}
              onChange={(e) => {
                component.setData({
                  "default.register.name": e.target.value,
                });
              }}
              label={component.ucfirst("name")}
            />
            {component
              .getData("error.register.name", [])
              .map(({ rule, message }) => {
                return (
                  <FormHelperText key={`auth-name-rule-${rule}`}>
                    {message}
                  </FormHelperText>
                );
              })}
          </FormControl>
          <FormControl
            fullWidth
            error={component.dataExists("error.register.username")}
            variant="outlined"
          >
            <InputLabel htmlFor="auth-username">
              {component.ucfirst("username")}
            </InputLabel>
            <OutlinedInput
              id="auth-username"
              value={component.getData("default.register.username", "")}
              onChange={(e) => {
                component.setData({
                  "default.register.username": e.target.value,
                });
              }}
              label={component.ucfirst("username")}
            />
            {component
              .getData("error.register.username", [])
              .map(({ rule, message }) => {
                return (
                  <FormHelperText key={`auth-username-rule-${rule}`}>
                    {message}
                  </FormHelperText>
                );
              })}
          </FormControl>
          <FormControl
            fullWidth
            error={component.dataExists("error.register.password")}
            variant="outlined"
          >
            <InputLabel htmlFor="auth-password">
              {component.ucfirst("password")}
            </InputLabel>
            <OutlinedInput
              id="auth-password"
              type={showPassword ? "text" : "password"}
              value={component.getData("default.register.password", "")}
              onChange={(e) => {
                component.setData({
                  "default.register.password": e.target.value,
                });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label={component.ucfirst("password")}
            />
            {component
              .getData("error.register.password", [])
              .map(({ rule, message }) => {
                return (
                  <FormHelperText key={`auth-password-rule-${rule}`}>
                    {message}
                  </FormHelperText>
                );
              })}
          </FormControl>
          <FormControl
            fullWidth
            error={component.dataExists("error.register.verify")}
            variant="outlined"
          >
            <InputLabel htmlFor="auth-password">
              {component.ucfirst("verify")}
            </InputLabel>
            <OutlinedInput
              id="auth-verify"
              type={showPassword ? "text" : "password"}
              value={component.getData("default.register.verify", "")}
              onChange={(e) => {
                component.setData({
                  "default.register.verify": e.target.value,
                });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label={component.ucfirst("verify")}
            />
            {component
              .getData("error.register.verify", [])
              .map(({ rule, message }) => {
                return (
                  <FormHelperText key={`auth-verify-rule-${rule}`}>
                    {message}
                  </FormHelperText>
                );
              })}
          </FormControl>
          <Button
            sx={{ marginBottom: "10px" }}
            type="submit"
            variant="contained"
            color="color1"
            fullWidth
            size="large"
          >
            {component.ucfirst("register")}
          </Button>
          <Button
            variant="text"
            color="color1"
            fullWidth
            size="small"
            onClick={() => component.openLoginDialog()}
          >
            {component.ucfirst("login-with-email")}
          </Button>
        </form>
      </DialogContent>
    </BootstrapDialog>
  );
}
