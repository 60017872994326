import * as React from "react";
import { Container, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Contents = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "48px",
  color: theme.palette.color1.main,
  ".title": {
    color: "black",
  },
  a: {
    color: theme.palette.color1.main,
    textDecoration: "none",
    textTransform: "capitalize",
    fontSize: "0.9rem",
    i: {
      fontSize: "0.8rem",
    },
  },
}));

export default function Header1(props) {
  const { component } = props;

  return (
    <Container>
      <Contents>
        <Link to="/summary">
          <i className="fa-solid fa-angle-left"></i>{" "}
          {component.ucfirst("summary")}
        </Link>
      </Contents>
    </Container>
  );
}
