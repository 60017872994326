import * as React from "react";
import { Paper, Grid, Box, styled, Typography } from "@mui/material";

const Contents = styled(Box)(({ theme }) => ({
  padding: "20px 0",
  ".order-summary-title": {
    textAlign: "center",
    marginBottom: "10px",
  },
}));

const SummaryContainer = styled(Paper)(({ theme }) => ({
  padding: "20px",
  ul: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    li: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "8px",
      ":last-child": {
        paddingTop: "8px",
        borderTop: `1px solid ${theme.palette.color5.main}`,
      },
    },
  },
}));

export default function Summary(props) {
  const { component } = props;
  const { dates = [], totals = {} } = component.getData("default.order") || {};
  const { grand = 0 } = totals;

  return (
    <Contents>
      <Grid justifyContent="center" container spacing={2}>
        <Grid item md={9} sm={12} xs={12}>
          <Typography className="order-summary-title" variant="h5">
            {component.ucfirst("order-summary")}
          </Typography>
          <SummaryContainer>
            <ul>
              {dates.map((orderDate) => {
                const { date, totals = {} } = orderDate;
                const { grand = 0 } = totals;

                return (
                  <li key={`order-date-${date}`}>
                    <Typography>
                      {component
                        .getApp()
                        .formatDate(date)
                        .map((value, index) =>
                          index === 0 ? component.ucfirst(value) : value
                        )
                        .join(" ")}
                    </Typography>
                    <Typography>{grand.toFixed(2)} &euro;</Typography>
                  </li>
                );
              })}
              {totals.discount > 0 && (
                <li>
                  <Typography variant="h7">
                    {component.ucfirst("discount")}
                  </Typography>
                  <Typography variant="h7">
                    -{totals.discount.toFixed(2)} &euro;
                  </Typography>
                </li>
              )}
              <li>
                <Typography variant="h7">
                  {component.ucfirst("total")}
                </Typography>
                <Typography variant="h7">{grand.toFixed(2)} &euro;</Typography>
              </li>
            </ul>
          </SummaryContainer>
        </Grid>
      </Grid>
    </Contents>
  );
}
