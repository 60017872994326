import * as React from "react";
import {
  Grid,
  Box,
  TextField,
  Paper,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  Select,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Contents = styled(Box)(({ theme }) => ({
  padding: "20px 0",
  ".order-summary-title": {
    textAlign: "center",
    marginBottom: "10px",
  },
}));

const CouponContainer = styled(Paper)(({ theme }) => ({
  padding: "20px",
}));

export default function PaymentType(props) {
  const { component } = props;
  const coupons = component.getData("default.coupons", []);
  const coupon = component.getData("default.order.coupon", null);
  const hasCoupons = coupons && coupons.length;
  const [selectCoupon, setSelectCoupon] = React.useState(false);

  React.useEffect(() => {
    setSelectCoupon(hasCoupons);
  }, [hasCoupons]);

  return (
    <Contents>
      <Grid justifyContent="center" container spacing={2}>
        <Grid item md={9} sm={12} xs={12}>
          <CouponContainer
            sx={{
              bgcolor: coupon ? "color1.main" : "",
              color: coupon ? "white" : "",
            }}
          >
            {coupon ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  {component.ucfirst("coupon")}: {coupon.name}
                </span>

                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={() => component.releaseCoupon()}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : selectCoupon ? (
              <FormControl fullWidth>
                <InputLabel id="select-coupon-label">
                  {component.ucfirst("select-coupon")}
                </InputLabel>
                <Select
                  labelId="select-coupon-label"
                  id="select-coupon-field"
                  value=""
                  label={component.ucfirst("select-coupon")}
                  onChange={(e) => {
                    if (e.target.value === "add-new-coupon") {
                      setSelectCoupon(false);
                    } else {
                      component.updateCoupon(e.target.value);
                    }
                  }}
                >
                  {coupons.map((coupon) => {
                    const { _id, name } = coupon;

                    return (
                      <MenuItem key={`coupon-${_id}`} value={_id}>
                        <i className="fa-regular fa-ticket-simple"></i>&nbsp;
                        {name}
                      </MenuItem>
                    );
                  })}
                  <MenuItem
                    value="add-new-coupon"
                    sx={{
                      justifyContent: "center",
                      mx: 1,
                      bgcolor: "color1",
                    }}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <i className="fa-solid fa-plus"></i>&nbsp;
                      {component.ucfirst("add")}
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Box
                component="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  component.addCoupon();
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <TextField
                    sx={{ flex: 1 }}
                    id="coupon-field"
                    label={component.ucfirst("type-your-coupon")}
                    variant="outlined"
                    onChange={(e) =>
                      component.setData({
                        "default.newCoupon": e.target.value,
                      })
                    }
                  />
                  <Button
                    sx={{ px: 4 }}
                    type="submit"
                    variant="contained"
                    color="color1"
                    // onClick={() => component.addCoupon()}
                  >
                    {component.ucfirst("apply")}
                  </Button>
                </Box>
                {coupons.length > 0 && (
                  <Button
                    fullWidth
                    onClick={() => setSelectCoupon(true)}
                    color="color1"
                  >
                    {component.ucfirst("select-coupon")}
                  </Button>
                )}
              </Box>
            )}
          </CouponContainer>
        </Grid>
      </Grid>
    </Contents>
  );
}
