import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widget/header/header1";

export default class Headers extends Component {
  template = templateDefault;
  id = "page-header";

  onAuth({ auth }) {
    this.refresh();
  }
}
