import { Container, Box, styled } from "@mui/material";

const Copyright = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: "center",
  a: {
    textDecoration: "none",
  },
}));

export default function Footer1(props) {
  return (
    <Container maxWidth={false} sx={{ height: "100%" }}>
      <Copyright>
        Copyright &copy;{" "}
        <a href="https://www.automatapp.com" target="_blank" rel="noreferrer">
          Automatapp
        </a>{" "}
        {new Date().getFullYear()}
      </Copyright>
    </Container>
  );
}
