import Helpers from "../../../modules/Core/Helpers/Helpers";
import Main from "../../Api/Main";
import Service from "../Service";

class Order extends Service {
  order = null;

  fetchOrder(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .retrieve(account, repository, {
          params: {
            orderId,
          },
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  fetchPaymentTypes(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      Main.paymentTypes(account, repository).then((r) => {
        if (this.setOrder(r)) {
          resolve(r);
        } else {
          reject(r);
        }
      });
    });
  }

  updatePaymentType(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      paymentTypeId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "update", "paymentType", {
          orderId,
          paymentTypeId,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  updateAddress(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      shippingAddressId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "update", "address", {
          orderId,
          shippingAddressId,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  updateSpot(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      spotId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "update", "spot", {
          orderId,
          spotId,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  updateItem(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      orderData,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "update", "item", {
          orderId,
          orderData,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  updateHour(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      orderData,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "update", "hour", {
          orderId,
          orderData,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  updateService(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      serviceId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "update", "service", {
          orderId,
          serviceId,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  updateCoupon(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      couponCode,
      couponId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "update", "coupon", {
          orderId,
          couponCode,
          couponId,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  releaseCoupon(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "update", "coupon", { orderId })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  prepare(data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "prepare", "order", {
          ...{ orderId },
          ...data,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  preparePayment(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "prepare", "payment", {
          orderId,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  verifyPayment(data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "verify", "payment", {
          ...{ orderId },
          ...data,
        })
        .then((r) => {
          if (this.setOrder(r)) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  place(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      transaction = null,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      Main.order
        .action(account, repository, "place", "order", {
          orderId,
          transaction,
        })
        .then((r) => {
          if (r.isValid()) {
            this.delete();
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  setOrder(r) {
    const valid = r.isValid();

    if (valid) {
      const order = r.getData();

      if (order) {
        this.order = order;
        Helpers.State.set("order_id", order._id, {
          domain: Helpers.Env.get("domain"),
          path: "/",
        });
      }
    } else {
      const { type } = r.getData() || {};

      if (
        ["order-missing", "order-placed", "order-expired"].indexOf(type) >= 0
      ) {
        this.delete();
      }
    }

    return valid;
  }

  getOrder() {
    return this.order;
  }

  delete() {
    Helpers.State.remove("order_id", {
      domain: Helpers.Env.get("domain"),
      path: "/",
    });
    this.order = null;
    return this;
  }

  getOrderId() {
    return Helpers.State.get("order_id", null, { path: "/" });
  }
}

export default new Order();
