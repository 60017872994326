import Dialog from "../Dialog";
import templateDefault from "../../templates/default/dialog/profile";
import { Auth } from "../../Services/Default";

export default class Profile extends Dialog {
  template = templateDefault;

  id = "profile-dialog";

  openDialog(options) {
    super.openDialog(options);
    this.retrieveProfile();
  }

  async retrieveProfile() {
    try {
      const response = await Auth.retrieveProfile();

      this.setData({ "default.profile": response.getData() });
    } catch (r) {
      this.closeDialog();
      this.getApp().openDialog("login");
    }
  }

  async submit(e) {
    e.preventDefault();

    const { name, username, password, verify } = this.getData(
      "default.profile",
      {}
    );

    if (password && verify && password !== verify) {
      this.getPage().openMainMessage(
        this.ucfirst("password-mismatch"),
        "error"
      );
      return;
    }

    const errors = this.getHelpers("validate").validate(
      { name, username, password, verify },
      {
        name: [
          {
            rule: "required",
            message: this.ucfirst("required"),
          },
        ],
      }
    );

    this.setData({
      "error.profile": errors || {},
      message: {},
    });

    if (!errors) {
      try {
        await Auth.updateProfile({
          name,
          username,
          password,
          verify,
        });

        this.setData({
          "default.profile.password": "",
          "default.profile.verify": "",
        });

        this.getPage().openMainMessage(this.ucfirst("save-done"));
      } catch (e) {
        this.getPage().openMainMessage(this.ucfirst(e.getMessage()), "error");
      }
    }
  }
}
