import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Header1 from "../../../Components/Widget/Header/Header1";
import HeaderDates1 from "../../../Components/Widget/Header/Menu/Dates1";
import PromptMessage from "../../../Components/Widget/Header/Menu/PromptMessage";
import Footer1 from "../widget/footer/footer1";
import MenuMeals from "../../../Components/Widget/Menu/Meals";

const PageAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "white",
  "& .MuiToolbar-gutters": {
    padding: 0,
  },
  ".logo": {
    color: "black",
  },
  ".login-btn": {
    color: "black",
  },
  i: {
    color: "black",
  },
}));

const PageAppBarToolbar = styled(Toolbar)(({ theme }) => ({
  ".toolbar-contents": {
    width: "100vw",
  },
}));

const Page = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.color2.main,
  position: "relative",
  minHeight: "100vh",
}));

const MainContainer = styled(Box)(({ theme }) => ({
  paddingBottom: "60px",
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  height: "43px",
  width: "100vw",
  position: "absolute",
  bottom: 0,
  color: "black",
  a: {
    color: "black",
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    target: props.window ? props.window() : undefined,
  });

  return (
    <Slide
      appear={false}
      direction="down"
      in={window.innerWidth > 900 || !trigger}
    >
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Menu(props) {
  React.useEffect(() => {
    const resize = () => {
      document.querySelector("main").style.marginTop = `${
        window.innerWidth > 900 ? 114 : 152
      }px`;
    };

    window.addEventListener("resize", resize);

    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <Page>
      <HideOnScroll {...props}>
        <PageAppBar>
          <PageAppBarToolbar>
            <Box className="toolbar-contents">
              <Header1 {...props} />
              <Divider />
              <HeaderDates1 {...props} />
              {/* <Divider /> */}
              <PromptMessage {...props} />
            </Box>
          </PageAppBarToolbar>
        </PageAppBar>
      </HideOnScroll>
      {/* <PageAppBarToolbar /> */}
      <MainContainer component="main">
        <Container>
          <MenuMeals />
        </Container>
      </MainContainer>
      <FooterContainer component="footer">
        <Footer1 {...props} />
      </FooterContainer>
    </Page>
  );
}
