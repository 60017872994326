import { createTheme } from "@mui/material/styles";

// const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

const theme = createTheme({
  palette: {
    // mode: isDark ? "dark" : "light",
    color1: {
      main: "#cf9545",
      contrastText: "#fff",
    },
    color2: {
      main: "#f8f8f8",
      contrastText: "#000",
    },
    color3: {
      main: "black",
      contrastText: "#fff",
    },
    color4: {
      main: "#d32f2f",
      contrastText: "#fff",
    },
    color5: {
      main: "#dbdbdb",
      contrastText: "#fff",
    },
    color6: {
      main: "#5fc8b4",
      contrastText: "#fff",
    },
    facebook: {
      main: "#4267b2",
      contrastText: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontSize: 14,
    button: {
      textTransform: "none",
    },
  },
  zIndex: {
    index1: 500,
    index2: 1000,
    index3: 1500,
    index4: 2000,
    super: 1000000,
  },
});

export default theme;
