import Helpers from "../../../../modules/Core/Helpers/Helpers";
import { Component } from "../../../../modules/Core/Component";
import { Order, Hook } from "../../../Services/Default";
import templateDefault from "../../../templates/default/widget/menu/meals";

export default class Meals extends Component {
  template = templateDefault;
  id = "menu-meals";

  onLoad(data) {
    super.onLoad(data);
    this.fetchMealTypes();
  }

  async fetchMealTypes() {
    const mealTypesResponse = await Hook.get("meal-types");

    if (mealTypesResponse.isValid()) {
      this.setData({
        "default.mealTypes": this.prepareMealTypes(mealTypesResponse.getData()),
      });

      this.buildMealTypes();
    }
  }

  setMeals(meals) {
    meals = this.prepareMeals(meals);

    this.setData({
      "default.meals": meals,
      "default.mealsAmount": meals.length,
    });

    this.assignPendingItem().buildMealTypes();

    return this;
  }

  prepareMealTypes(mealTypes) {
    return mealTypes.map(({ _id, _values }) => {
      return { ..._values, ...{ _id } };
    });
  }

  buildMealTypes() {
    let { mealTypes = [], meals = [] } = this.getData("default", {});

    mealTypes = mealTypes.map((mealType) => {
      const { slug } = mealType;

      mealType.meals = meals.filter(({ meal_types }) => {
        return meal_types.find(
          (mealType) => mealType.item._values.slug === slug
        );
      });

      return mealType;
    });

    this.setData({ "default.mealTypes": mealTypes });

    return this;
  }

  prepareMeals(meals) {
    return meals.map(({ active, visible, item, price, value_prices = {} }) => {
      const { _id, _values } = item;
      const { meal_options = [] } = _values;

      _values.price_b2c = price ? price : _values.price_b2c;

      Object.keys(value_prices).forEach((slug) => {
        const mealOption = meal_options.find(
          (option) => option.item._values.slug === slug
        );

        if (mealOption) {
          mealOption.price = value_prices[slug];
        }
      });

      return { ..._values, ...{ _id, active, visible } };
    });
  }

  assignPendingItem() {
    const {
      itemId,
      values = [],
      date,
    } = Helpers.State.getObject("pendind_item", null, {
      path: "/",
    }) || {};

    if (itemId && date) {
      const meals = this.getData("default.meals", []);
      const dateFound = this.getPage()
        .getData("default.spotDates", [])
        .find(({ _values }) => _values.date === date);

      const mealFound = meals.find((meal) => meal._id === itemId);

      if (dateFound && mealFound) {
        this.addToCart(itemId, values, date);
      }

      Helpers.State.remove("pendind_item", { path: "/" });
    }

    return this;
  }

  addPendingItem(data) {
    Helpers.State.setObject("pendind_item", data, { path: "/" });
    // this.getApp().openDialog("spots");
    this.getApp().openDialog("start-order");
  }

  findInstance(itemId, values) {
    values = values || [];

    const order = Order.getOrder();
    const date = this.getPage().getParam("date");
    const { dates = [] } = order;
    const { items = [] } =
      dates.find((orderDate) => orderDate.date === date) || {};
    const itemsFound = items.filter((item) => item._id === itemId);
    let instance = null;

    if (itemsFound.length) {
      itemsFound.forEach((item) => {
        if (item.values.length === values.length) {
          let same = true;

          item.values.forEach((itemValue) => {
            const { slug } = itemValue;
            same =
              same && values.find((value) => value.slug === slug)
                ? true
                : false;
          });

          if (same) {
            instance = item.instance;
          }
        }
      });
    }

    return instance ? instance : new Date().getTime();
  }

  async addToCart(itemId, values, date) {
    date = date || this.getPage().getParam("date");

    try {
      if (!Order.getOrder()) {
        throw Error();
      }

      const item = this.findItemById(itemId);

      if (item) {
        const price = item.price_b2c;
        const instance = this.findInstance(itemId, values);
        const amount = this.getMealAmount(itemId, instance) + 1;
        const order = await Order.updateItem({
          orderData: { itemId, instance, values, date, amount, price },
        });
        this.getPage().setOrder(order.getData());
      }
    } catch (e) {
      this.addPendingItem({ itemId, values, date });
    }

    return this;
  }

  async removeFromCart(itemId) {
    try {
      if (!Order.getOrder()) {
        throw Error();
      }

      //cannot remove from here
      const date = this.getPage().getParam("date");
      const { dates = [] } = Order.getOrder();
      const { items = [] } =
        dates.find((orderDate) => orderDate.date === date) || {};
      const item = items.find(({ _id }) => _id === itemId);

      if (item) {
        const { instance } = item;
        const date = this.getPage().getParam("date");
        const amount = this.getMealAmount(itemId, instance) - 1;
        const order = await Order.updateItem({
          orderData: { itemId, instance, date, amount },
        });
        this.getPage().setOrder(order.getData());
      }
    } catch (e) {
      this.getApp().openDialog("addresses");
    }

    return this;
  }

  openMealDialog(meal) {
    const date = this.getPage().getParam("date");
    const spot = this.getPage().getParam("spot");

    this.getApp().openDialog("meal", { spot, date, meal, type: "add" });
  }

  isDateOpened() {
    let dateOpened = false;
    const date = this.getPage().getParam("date");
    const spotDate = this.getPage()
      .getData("default.spotDates", [])
      .find(({ _values }) => _values.date === date);

    if (spotDate) {
      const { _values = {} } = spotDate;
      const { hours = [], opened = false } = _values;
      const now = new Date().getTime();

      dateOpened =
        opened &&
        hours.find(({ active, opens, closes }) => {
          opens = opens ? new Date(opens).getTime() : opens;
          closes = closes ? new Date(closes).getTime() : closes;

          return (
            active &&
            ((!opens && now < closes) || (opens && now > opens && now < closes))
          );
        })
          ? true
          : false;
    }

    return dateOpened;
  }

  getDateNow() {
    const date = this.getPage().getParam("date");
    const spotDate = this.getPage()
      .getData("default.spotDates", [])
      .find(({ _values }) => _values.date === date);
    let now = "";

    if (spotDate) {
      const { _values = {} } = spotDate;
      const { hours = [] } = _values;
      now = hours.find((hour) => !hour.from && !hour.to);

      return now
        ? [
            (now.opens &&
              now.opens
                .substring(now.opens.length, now.opens.indexOf(" "))
                .substring(0, 6)) ||
              "-",

            (now.closes &&
              now.closes
                .substring(now.closes.length, now.closes.indexOf(" "))
                .substring(0, 6)) ||
              "-",
          ].join(` ${this.trans("to")} `)
        : "";
    }

    return now;
  }

  canRemoveCart(itemId) {
    return this.getMealAmount(itemId) > 0;
  }

  getMealAmount(mealId, instance) {
    const date = this.getPage().getParam("date");
    const order = Order.getOrder();
    let amount = 0;

    if (order) {
      const { dates = [] } = order;
      const { items = [] } =
        dates.find((orderDate) => orderDate.date === date) || {};

      if (instance) {
        const item = items.find(
          (item) => item._id === mealId && item.instance === instance
        );

        if (item) {
          amount = item.amount;
        }
      } else {
        amount = items
          .filter(({ _id }) => _id === mealId)
          .reduce((a, b) => a + b.amount, 0);
      }
    }

    return amount;
  }

  getDateAmount() {
    const date = this.getPage().getParam("date");
    return this.getPage().getDateAmount(date);
  }

  findItemById(id) {
    return this.getData("default.meals", []).find((meal) => meal._id === id);
  }
}
