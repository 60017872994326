import { Box, Container, styled } from "@mui/material";

const Contents = styled(Box)(({ theme }) => ({
  height: "38px",
  color: "black",
  padding: "7px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Message = styled("span")(({ theme }) => ({
  cursor: "pointer",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  i: {
    paddingRight: "10px",
  },
}));

export default function PromptMessage(props) {
  const { component } = props;
  const promptMessage = component.getData("default.promptMessage", "");
  const orderType = component.getPage().getOrderType();

  return (
    <Container
      sx={{
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        display: {
          xs: "block",
          // sm: "block",
          md: "none",
          // lg: "none",
          // xl: "none",
        },
      }}
    >
      <Contents>
        <Message
          onClick={() => {
            component.getPage().openStartOrderDialog();
          }}
        >
          {orderType ? (
            orderType === "delivery" ? (
              <i className="fa-duotone fa-moped"></i>
            ) : (
              <i className="fa-solid fa-shop"></i>
            )
          ) : (
            <i className="fa-solid fa-sort-down"></i>
          )}
          {promptMessage ? promptMessage : component.ucfirst("new-order")}
        </Message>
      </Contents>
    </Container>
  );
}
