import Home from "./Page/Home";
import Menu from "./Page/Menu";
import Summary from "./Page/Summary";
import Checkout from "./Page/Checkout";
import VivaResult from "./Page/Viva/VivaResult";
import NotFound from "./Page/NotFound";

const Module = {
  Home,
  Menu,
  Summary,
  Checkout,
  VivaResult,
  NotFound,
};

export default Module;
