import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(2, 2),
    button: {
      marginTop: "5px",
    },
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0, 2, 2, 2),
    "#spots-select": {
      marginBottom: theme.spacing(1),
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1, 2, 2),
  },
  "& .MuiAlert-standard": {
    marginBottom: "10px",
  },
}));

const handleClose = (props) => {
  const { component } = props;
  component.closeDialog();
  // component.getPage().openStartOrderDialog();
};

const SelectSpots = (props) => {
  const { component, spots } = props;

  if (!spots)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2,
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (!spots.length)
    return (
      <Alert severity="error">
        {component.ucfirst("delivery-spots-not-found")}
      </Alert>
    );

  return (
    <Select
      value={component.getData("default.selectedSpot", "")}
      onChange={(event) => {
        component.setData({
          "default.selectedSpot": event.target.value,
        });
      }}
    >
      {spots.map((spot) => {
        return (
          <MenuItem key={`spot-${spot._id}`} value={spot._id}>
            <span>
              {spot._values.name} - {spot._values.address}
            </span>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default function Spots(props) {
  const { component } = props;
  const spots = component.getData("default.spots");

  return (
    <BootstrapDialog
      onClose={() => handleClose(props)}
      aria-labelledby="customized-dialog-title"
      open={component.getData("default.opened", false)}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle className="dialog-title">
        {component.ucfirst("take-away")}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(props)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl id="spots-select" fullWidth>
          <SelectSpots component={component} spots={spots} />
          {/* <Select
            value={component.getData("default.selectedSpot", "")}
            onChange={(event) => {
              component.setData({
                "default.selectedSpot": event.target.value,
              });
            }}
          >
            {spots.map((spot) => {
              return (
                <MenuItem key={`spot-${spot._id}`} value={spot._id}>
                  <span>
                    {spot._values.name} - {spot._values.address}
                  </span>
                </MenuItem>
              );
            })}
          </Select> */}
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="color1"
          fullWidth
          size="large"
          onClick={() => component.createOrder()}
        >
          {component.ucfirst("create-new-order")}
        </Button>
      </DialogContent>
    </BootstrapDialog>
  );
}
