import Page from "../Page";
import templateDefault from "../../templates/default/page/summary";
import { Hook, Order } from "../../Services/Default";

export default class Summary extends Page {
  template = templateDefault;

  title = "summary";
  datesHours = {};
  prices = [];

  onLoad(data) {
    super.onLoad(data);
    this.scrollToTop();
    this.fetchOrder();
  }

  onAuth({ auth }) {
    if (!auth.isAuthenticated()) {
      this.redirectToSpot();
    }
  }

  async fetchOrder() {
    try {
      const orderResponse = await Order.fetchOrder();
      const order = orderResponse.getData() || {};
      this.setOrder(order, true);
    } catch (e) {
      this.redirectToSpot();
    }
  }

  updateNowAuto(order) {
    const { dates = [] } = order;

    const orderDates = dates.filter((date) => {
      return date.date === this.getHelpers("date-time").getCurrentDate();
    });

    if (orderDates.length) {
      const [today] = orderDates;

      if (!today.hour) {
        this.updateHour(today.date, "now");
      }
    }
  }

  async updateItem(itemId, instance, date, amount) {
    try {
      const orderResponse = await Order.updateItem({
        orderData: { itemId, instance, date, amount },
      });
      const order = orderResponse.getData() || {};
      this.setOrder(order);
    } catch (e) {
      this.redirectToSpot();
    }

    return this;
  }

  async updateHour(date, hour) {
    if (!hour) return;

    try {
      const dateHours = this.datesHours[date];

      if (dateHours) {
        hour = dateHours.find((dateHour) => {
          return (dateHour.now && hour === "now") || dateHour.from === hour;
        });
      }

      const orderResponse = await Order.updateHour({
        orderData: { date, hour },
      });
      const order = orderResponse.getData() || {};
      this.setOrder(order);
    } catch (e) {
      this.redirectToSpot();
    }

    return this;
  }

  filterHours(hours) {
    const now = new Date().getTime();

    hours = hours.filter(({ active, opens, closes }) => {
      opens = opens ? new Date(opens).getTime() : opens;
      closes = closes ? new Date(closes).getTime() : closes;

      return (
        active &&
        ((!opens && now < closes) || (opens && now > opens && now < closes))
      );
    });

    return hours;
  }

  dateTimeToNumber(hour) {
    return hour.replaceAll("-", "").replaceAll(" ", "").replaceAll(":", "") * 1;
  }

  async setOrder(order, first) {
    const { dates = [] } = order;

    if (!dates.length) {
      this.redirectToSpot();
      return;
    }

    dates.sort((a, b) => {
      return a.date.replaceAll("-", "") * 1 - b.date.replaceAll("-", "") * 1;
    });

    if (first) {
      await this.fetchDateAssets(order);
    }

    this.mergeDatesHours(order);
    this.setData({ "default.order": order });

    this.updateNowAuto(order);

    return this;
  }

  async fetchDateAssets(order) {
    const { dates = [], spot } = order;
    const datesHours = {};

    for (let i in dates) {
      const _date = dates[i].date;
      const response = await Hook.get("date-hours", {
        params: {
          date: _date,
          spot: spot.slug,
        },
      });

      const data = response.getData();

      if (response.isValid() && data) {
        const { _values = {} } = data;
        const { hours = [], prices = [] } = _values;
        datesHours[_date] = hours;
        this.setPrices(prices);
      }
    }

    this.datesHours = datesHours;
  }

  setPrices(prices) {
    this.prices = prices;
    return this;
  }

  getPrice(slug, value) {
    const price = this.prices.find((price) =>
      value
        ? price.item === slug && price.value === value
        : price.item === slug && !price.value
    );

    return price ? price.price : 0;
  }

  mergeDatesHours(order) {
    const { dates = [] } = order;

    dates.forEach((date) => {
      date.hours = this.datesHours[date.date];
    });

    return this;
  }

  continue() {
    const order = this.getData("default.order");

    if (order) {
      const { dates } = order;
      let valid = true;

      for (let i in dates) {
        valid = valid && (dates[i].hour ? true : false);
      }

      if (valid) {
        this.redirect("/checkout");
      } else {
        this.getPage().openMainMessage(
          this.ucfirst("delivery-time-missing"),
          "error"
        );
      }
    }

    return this;
  }

  redirectToSpot() {
    this.redirect("/spot");
    return this;
  }

  async updateValues(meal, values, date) {
    try {
      if (!Order.getOrder()) {
        throw Error();
      }

      const itemId = meal._id;
      const instance = meal.instance;
      let amount = meal.amount;

      const itemFound = this.findInstance(itemId, instance, values, date);

      if (itemFound) {
        amount += itemFound.amount;

        await Order.updateItem({
          orderData: {
            itemId: itemFound._id,
            instance: itemFound.instance,
            date,
            amount: 0,
          },
        });
      }

      const orderResponse = await Order.updateItem({
        orderData: { itemId, instance, date, values, amount },
      });
      const order = orderResponse.getData() || {};
      this.setOrder(order);
    } catch (r) {
      this.getPage().openMainMessage(this.ucfirst(r.getMessage()), "error");
    }

    return this;
  }

  findInstance(itemId, instance, values, date) {
    const { dates } = Order.getOrder();
    const orderDate = dates.find((orderDate) => orderDate.date === date);
    const { items = [] } = orderDate;
    const itemsFound = items.filter((item) => item._id === itemId);

    let itemFound = null;

    if (itemsFound.length) {
      itemsFound.forEach((item) => {
        if (
          item.instance !== instance &&
          item.values.length === values.length
        ) {
          let same = true;

          item.values.forEach((itemValue) => {
            const { slug } = itemValue;
            same =
              same && values.find((value) => value.slug === slug)
                ? true
                : false;
          });

          if (same) {
            itemFound = item;
          }
        }
      });
    }

    return itemFound;
  }
}
