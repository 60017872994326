import Helpers from "../../../modules/Core/Helpers/Helpers";
import Main from "../../Api/Main";
import Service from "../Service";

class Coupon extends Service {
  fetchCoupons(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      Main.coupon.collection(account, repository).then((r) => {
        if (r.isValid()) {
          resolve(r);
        } else {
          reject(r);
        }
      });
    });
  }

  addCoupon(coupon, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      Main.coupon
        .create(account, repository, {
          coupon,
        })
        .then((r) => {
          if (r.isValid()) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
  }

  deleteCoupon(couponId, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      Main.coupon.delete(account, repository, couponId).then((r) => {
        if (r.isValid()) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Coupon();
