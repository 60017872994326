import * as React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Badge,
  Box,
  Button,
  Tabs,
  Tab,
  Grid,
  CircularProgress,
  styled,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const DatesTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "#5fc8b4",
  },
  "& .MuiButtonBase-root": {
    textTransform: "capitalize",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    ":last-child": {
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  "& .MuiButtonBase-root.Mui-selected": {
    backgroundColor: "#5fc8b4",
    color: "white",
    " .MuiBadge-badge": {
      background: "white",
      color: "black",
    },
  },
  "& .MuiBadge-badge": {
    right: -18,
    top: 28,
    background: "#5fc8b4",
    color: "white",
  },
}));

const PromptNewOrder = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  cursor: "pointer",
  fontSize: "0.9rem",
  span: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "i.fa-sort-down": {
    marginBottom: "8px",
  },
  i: {
    // marginBottom: "8px",
    // border: "1px solid red",
    marginRight: theme.spacing(1),
  },
}));

const NoDaysAvailable = styled(Box)(({ theme }) => ({
  padding: "11px",
  color: "black",
}));

const ContinueButton = styled(Button)(({ theme }) => ({
  fontSize: "1.3rem",
  width: "100%",
  height: "100%",
  borderRadius: 0,
}));

export default function dates1(props) {
  const { component } = props;
  const dates = component.getData("default.dates");
  const date = component.getPage().getParam("date");
  const total = component.getPage().getOrderTotal();
  const promptMessage = component.getData("default.promptMessage", "");
  const orderType = component.getPage().getOrderType();

  return (
    <Container
      sx={{
        height: "48px",
        // width: "100%",
        bgcolor: "background.paper",
        // justifyContent: "center",
        // display: "flex",
        color: "black",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={2}
          sx={{
            // border: "1px solid green",
            display: {
              xs: "none",
              // sm: "none",
              md: "block",
              // lg: "block",
              // xl: "block",
            },
          }}
          onClick={() => {
            component.getPage().openStartOrderDialog();
          }}
        >
          <PromptNewOrder>
            {orderType ? (
              orderType === "delivery" ? (
                <i className="fa-duotone fa-moped "></i>
              ) : (
                <i className="fa-solid fa-shop"></i>
              )
            ) : (
              <i className="fa-solid fa-sort-down"></i>
            )}
            <span>
              {promptMessage
                ? promptMessage
                : component.ucfirst("start-new-order")}
            </span>
          </PromptNewOrder>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {dates === null ? (
            <CircularProgress />
          ) : dates.length > 0 ? (
            <DatesTabs
              value={date}
              onChange={(event, value) => component.changeDate(value)}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
            >
              {dates.map(({ _values }) => {
                const { date } = _values || {};
                const formatedDate = component.getApp().formatDate(date);

                return (
                  <Tab
                    key={`page-header-menu-date-${date}`}
                    sx={{
                      padding: "0 30px",
                    }}
                    label={
                      <Badge
                        badgeContent={
                          component.getPage().getDateAmount(date) || null
                        }
                        color="primary"
                      >
                        {component.ucfirst(formatedDate[0])}
                        <br />
                        {formatedDate[1]}
                      </Badge>
                    }
                    value={date}
                  />
                );
              })}
            </DatesTabs>
          ) : (
            <NoDaysAvailable>
              {component.ucfirst("no-days-available")}
            </NoDaysAvailable>
          )}
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: {
              xs: "none",
              // sm: "none",
              md: "block",
              // lg: "block",
              // xl: "block",
            },
          }}
        >
          <ContinueButton
            component={Link}
            to="/summary"
            variant="contained"
            color="color4"
            disabled={total === 0}
            startIcon={<ShoppingCartIcon />}
          >
            {component.ucfirst("continue")}
          </ContinueButton>
        </Grid>
      </Grid>
      {/* <Box
        sx={{
          // flex: 1,
          width: "200px",
          border: "1px solid green",
          display: {
            xs: "none",
            // sm: "none",
            md: "block",
            // lg: "block",
            // xl: "block",
          },
        }}
        onClick={() => component.getPage().openStartOrderDialog()}
      >
        <SelectAddress>
          <i className="fa-solid fa-sort-down"></i>
          {address ? address.address : component.ucfirst("select-address")}
        </SelectAddress>
      </Box>
      {dates.length > 0 ? (
        <DatesTabs
          value={date}
          onChange={(event, value) => component.changeDate(value)}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
          // sx={{ flex: 1 }}
        >
          {dates.map(({ _values }) => {
            const { date } = _values || {};
            const formatedDate = component.formatDate(date);

            return (
              <Tab
                key={`page-header-menu-date-${date}`}
                sx={{
                  padding: "0 30px",
                }}
                label={
                  <Badge
                    badgeContent={
                      component.getPage().getDateAmount(date) || null
                    }
                    color="primary"
                  >
                    {component.ucfirst(formatedDate[0])}
                    <br />
                    {formatedDate[1]}
                  </Badge>
                }
                value={date}
              />
            );
          })}
        </DatesTabs>
      ) : (
        <NoDaysAvailable>
          {component.ucfirst("no-days-available")}
        </NoDaysAvailable>
      )}
      <Box
        sx={{
          flex: 1,
          display: {
            xs: "none",
            // sm: "none",
            md: "block",
            // lg: "block",
            // xl: "block",
          },
        }}
      >
        continue
      </Box> */}
    </Container>
  );
}
