import Helpers from "../../../modules/Core/Helpers/Helpers";
import Main from "../../Api/Main";
import Service from "../Service";

class Hook extends Service {
  get(hook, options) {
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();
    const version = 1;

    return new Promise((resolve, reject) => {
      Main.hook(account, repository, hook, version, options).then((r) => {
        if (r.isValid()) {
          resolve(r);
        } else {
          reject(r);
        }
      });
    });
  }

  post(hook, options) {
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();
    const version = 2;

    return new Promise((resolve, reject) => {
      Main.hookPost(account, repository, hook, version, options).then((r) => {
        if (r.isValid()) {
          resolve(r);
        } else {
          reject(r);
        }
      });
    });
  }
}

export default new Hook();
