import { Component } from "../../../../../modules/Core/Component";
import templateDefault from "../../../../templates/default/widget/header/menu/dates1";

export default class Dates1 extends Component {
  template = templateDefault;
  id = "page-header-menu-dates";
  group = "header-message";

  setPromptMessage(promptMessage) {
    this.setData({ "default.promptMessage": promptMessage });
    return this;
  }

  setDates(dates) {
    this.setData({ "default.dates": dates });
    return this;
  }

  changeDate(newDate) {
    const spot = this.getPage().getParam("spot");
    this.getPage().redirect(`/spot/${spot}/date/${newDate}`);
  }

  // formatDate(date) {
  //   const dates = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  //   date = new Date(date);
  //   return [dates[date.getDay()], date.getDate()];
  // }
}
