import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Header1 from "../../../Components/Widget/Header/Header1";
import CheckoutHeader1 from "../widget/header/checkout/header1";
import CheckoutAddress from "../widget/checkout/address";
import CheckoutSummary from "../widget/checkout/summary";
import CheckoutCoupon from "../widget/checkout/coupon";
import CheckoutPaymentType from "../widget/checkout/payment_type";
import CheckoutComplete from "../widget/checkout/complete";
import Footer1 from "../widget/footer/footer1";

const PageAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "white",
  "& .MuiToolbar-gutters": {
    padding: 0,
  },
  ".logo": {
    color: "black",
  },
  ".login-btn": {
    color: "black",
  },
  ".account-icon": {
    color: "black",
  },
}));

const PageAppBarToolbar = styled(Toolbar)(({ theme }) => ({
  height: "112px",
  ".toolbar-contents": {
    width: "100vw",
  },
}));

const Page = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.color2.main,
  position: "relative",
  minHeight: "100vh",
}));

const MainContainer = styled(Box)(({ theme }) => ({
  paddingBottom: "60px",
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  height: "43px",
  width: "100vw",
  position: "absolute",
  bottom: 0,
  color: "black",
  a: {
    color: "black",
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Menu(props) {
  return (
    <Page>
      <HideOnScroll {...props}>
        <PageAppBar>
          <PageAppBarToolbar>
            <Box className="toolbar-contents">
              <Header1 {...props} />
              <Divider />
              <CheckoutHeader1 {...props} />
            </Box>
          </PageAppBarToolbar>
        </PageAppBar>
      </HideOnScroll>
      <PageAppBarToolbar />
      <MainContainer component="main">
        <Container>
          <CheckoutAddress {...props} />
          <CheckoutCoupon {...props} />
          <CheckoutSummary {...props} />
          <CheckoutPaymentType {...props} />
          <CheckoutComplete {...props} />
        </Container>
      </MainContainer>
      <FooterContainer component="footer">
        <Footer1 {...props} />
      </FooterContainer>
    </Page>
  );
}
