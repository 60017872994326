import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    paddingLeft: theme.spacing(2),
  },
  "& .register-with-email": {
    marginBottom: "10px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
    ".MuiFormControl-root": {
      marginBottom: theme.spacing(2),
    },
  },
}));

const OrText = styled(Box)(({ theme }) => ({
  "&": {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    ".line": {
      position: "absolute",
      left: 0,
      border: `1px solid ${theme.palette.color5.main};`,
      width: "100%",
    },
    ".text": {
      position: "absolute",
      fontSize: "1.2rem",
      background: "white",
      padding: "0 10px",
    },
  },
}));

const handleClose = (props) => {
  const { component } = props;
  component.closeDialog();
};

export default function Login(props) {
  const { component } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <BootstrapDialog
      onClose={() => handleClose(props)}
      aria-labelledby="customized-dialog-title"
      open={component.getData("default.opened", false)}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle className="dialog-title">
        {component.ucfirst("login")}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(props)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => component.login(e)}>
          <FormControl
            fullWidth
            error={component.dataExists("error.login.username")}
            variant="outlined"
          >
            <InputLabel htmlFor="auth-username">
              {component.ucfirst("username")}
            </InputLabel>
            <OutlinedInput
              id="auth-username"
              value={component.getData("default.login.username", "")}
              onChange={(e) => {
                component.setData({
                  "default.login.username": e.target.value,
                });
              }}
              label={component.ucfirst("username")}
            />
            {component
              .getData("error.login.username", [])
              .map(({ rule, message }) => {
                return (
                  <FormHelperText key={`auth-username-rule-${rule}`}>
                    {message}
                  </FormHelperText>
                );
              })}
          </FormControl>
          <FormControl
            fullWidth
            error={component.dataExists("error.login.password")}
            variant="outlined"
          >
            <InputLabel htmlFor="auth-password">
              {component.ucfirst("password")}
            </InputLabel>
            <OutlinedInput
              id="auth-password"
              type={showPassword ? "text" : "password"}
              value={component.getData("default.login.password", "")}
              onChange={(e) => {
                component.setData({
                  "default.login.password": e.target.value,
                });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label={component.ucfirst("password")}
            />
            {component
              .getData("error.login.password", [])
              .map(({ rule, message }) => {
                return (
                  <FormHelperText key={`auth-password-rule-${rule}`}>
                    {message}
                  </FormHelperText>
                );
              })}
          </FormControl>
          <Button
            sx={{ marginBottom: "10px" }}
            type="submit"
            variant="contained"
            color="color1"
            fullWidth
            size="large"
          >
            {component.ucfirst("login")}
          </Button>
          <Button
            variant="text"
            color="color1"
            fullWidth
            size="small"
            onClick={() => component.openRegisterDialog()}
          >
            {component.ucfirst("register-with-email")}
          </Button>
        </form>
        <OrText>
          <Box className="line"></Box>
          <Box className="text">{component.trans("or")}</Box>
        </OrText>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <Button
              variant="contained"
              color="facebook"
              fullWidth
              size="large"
              sx={{ mb: 1 }}
              onClick={() => component.loginFB()}
            >
              {component.ucfirst("facebook")}
            </Button>

            <Box
              id="google-sign-in-btn"
              type="submit"
              variant="contained"
            ></Box>
          </Box>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}
