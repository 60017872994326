import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widget/message/message1";

export default class Message1 extends Component {
  template = templateDefault;
  widget = true;

  id = "main-message";

  open(message, severity) {
    severity = severity || "success";

    this.setData({
      default: {
        message,
        severity,
        opened: true,
      },
    });

    return this;
  }

  close() {
    this.setData({
      "default.opened": false,
    });

    return this;
  }
}
