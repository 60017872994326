import Page from "../Page";

export default class Home extends Page {
  title = "not-found";

  onLoad(data) {
    super.onLoad(data);
    this.redirect("spot");
  }
}
