import Page from "../Page";
import templateDefault from "../../templates/default/page/menu";
import { Hook, Order } from "../../Services/Default";

export default class Menu extends Page {
  template = templateDefault;

  title = "home";

  logout() {
    super.logout();
    this.deleteOrder();
    this.setSpotDates([]);
    this.redirectToDefault();
  }

  onLoad(data) {
    super.onLoad(data);
    this.scrollToTop();
    this.fetchOrder();
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    const { match } = prevProps;

    const date = this.getParam("date");
    const spot = this.getParam("spot");

    if (!spot) {
      this.redirectToSpotMenu();
    } else if (match.params.date !== date) {
      this.fetchMenu();
    }
  }

  async redirectToSpotMenu() {
    let spot = this.getParam("spot");
    let date = this.getParam("date");
    let order = Order.getOrder();

    if (order) {
      const orderSpot = order.spot.slug;

      if (!spot) {
        spot = orderSpot;
      } else if (spot !== orderSpot) {
        this.redirectToDefault();
        return;
      }
    }

    const spotResponse = await Hook.get("spot", {
      params: {
        spot,
      },
    });

    if (spotResponse.isValid()) {
      const { _values = {} } = spotResponse.getData() || {};
      const { slug } = _values;

      spot = slug;
    }

    if (spot) {
      const spotDatesResponse = await Hook.get("spot-dates", {
        params: {
          spot,
        },
      });

      if (spotDatesResponse.isValid()) {
        this.setSpotDates(spotDatesResponse.getData());

        const newDate = this.getSpotFirstDate(date);

        if (!newDate || newDate !== date) {
          this.redirect(`/spot/${spot}/date/${newDate || ""}`);
        } else {
          this.fetchMenu();
        }
      }
    } else {
      this.redirectToDefault();
    }

    return this;
  }

  getSpotFirstDate(date) {
    const spotDates = this.getData("default.spotDates", []).map(
      ({ _values }) => _values.date
    );

    return date && spotDates.indexOf(date) >= 0
      ? date
      : spotDates.length
      ? spotDates[0]
      : null;
  }

  async fetchOrder() {
    try {
      const orderResponse = await Order.fetchOrder();

      if (orderResponse.isValid()) {
        const order = orderResponse.getData() || {};
        this.setOrder(order);
      }
    } catch (e) {
      //
    }

    this.redirectToSpotMenu();

    return this;
  }

  setSpotDates(spotDates) {
    this.setData({ "default.spotDates": spotDates });

    this.getComponents()
      .findById("page-header-menu-dates")
      .forEach((c) => c.setDates(spotDates));

    return this;
  }

  deleteOrder() {
    this.deleteData("default.order");
    this.setPromptMessage("");
    this.redirectToDefault();

    return this;
  }

  setOrder(order) {
    this.setData({ "default.order": order });

    const message =
      order.order_type === "delivery"
        ? order.shipping_address.address
        : order.spot.name;

    this.setPromptMessage(message);

    return this;
  }

  setPromptMessage(message) {
    this.getComponents()
      .findByGroup("header-message")
      .forEach((c) => c.setPromptMessage(message));

    return this;
  }

  redirectToDefault() {
    this.redirect("/spot");
    return this;
  }

  async fetchMenu() {
    const date = this.getParam("date");
    const spotDate = this.getData("default.spotDates", []).find(
      ({ _values }) => _values.date === date
    );
    let meals = [];

    if (spotDate) {
      const { _id } = spotDate;

      const menuResponse = await Hook.get("menu", {
        params: {
          id: _id,
        },
      });

      if (menuResponse.isValid()) {
        const { _values = {} } = menuResponse.getData() || {};
        meals = _values.meals || [];
      }
    }

    this.setMeals(meals);
  }

  setMeals(meals) {
    this.getComponents()
      .findById("menu-meals")
      .forEach((c) => c.setMeals(meals));

    return this;
  }

  getOrderTotal() {
    const order = Order.getOrder();
    let amount = 0;
    const { amounts = {} } = order || {};
    const { items = 0 } = amounts;
    amount = items;

    return amount;
  }

  getDateAmount(date) {
    const order = this.getData("default.order");
    const { amounts = 0 } =
      (order ? order.dates : []).find((orderDate) => orderDate.date === date) ||
      {};

    return amounts;
  }

  openStartOrderDialog() {
    this.getApp().openDialog("start-order");
    // this.getApp().openDialog("spots");
    return this;
  }

  getOrderType() {
    return this.getData("default.order.order_type");
  }
}
