import * as React from "react";
import {
  Grid,
  Box,
  Button,
  ButtonGroup,
  Typography,
  styled,
} from "@mui/material";

const Contents = styled(Box)(({ theme }) => ({
  padding: "20px 0",
  ".order-summary-title": {
    textAlign: "center",
    marginBottom: "10px",
  },
}));

const Icons = (props) => {
  const { type } = props;

  switch (type) {
    case "epay-credit":
    case "viva-credit": {
      return <i className="fa-solid fa-credit-card"></i>;
    }
    case "pos": {
      return <i className="fa-duotone fa-tablet-button"></i>;
    }
    case "cash":
    default: {
      return <i className="fa-solid fa-cash-register"></i>;
    }
  }
};

export default function PaymentType(props) {
  const { component } = props;
  const paymentTypes = component.getPage().getData("default.paymentTypes", []);
  const selectedPaymentType = component
    .getPage()
    .getData("default.order.paymentType.slug");

  return (
    <Contents>
      <Grid justifyContent="center" container spacing={2}>
        <Grid item md={9} sm={12} xs={12}>
          <Typography className="order-summary-title" variant="h5">
            {component.ucfirst("payment-type")}
          </Typography>
          <ButtonGroup
            orientation="vertical"
            aria-label="vertical contained button group"
            variant="contained"
            color="color6"
            fullWidth
          >
            {paymentTypes.map((paymentType) => {
              const { id, slug } = paymentType;

              return (
                <Button
                  key={`payment-type-${slug}`}
                  startIcon={<Icons type={slug} />}
                  sx={{ justifyContent: "left", py: 2 }}
                  size="large"
                  color={slug === selectedPaymentType ? "color6" : "color2"}
                  onClick={() => component.getPage().updatePaymentType(id)}
                  fullWidth
                >
                  {component.ucfirst(`payment-type-${slug}`)}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Contents>
  );
}
