import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(2, 2),
    button: {
      marginTop: "5px",
    },
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0, 2, 2, 2),
    form: {
      marginBottom: theme.spacing(1),
    },
  },
  "& .MuiAlert-standard": {
    marginBottom: "10px",
  },
}));

const handleClose = (props) => {
  const { component } = props;
  component.closeDialog();
  //   component.getApp().openDialog("addresses");
};

export default function NewAddress(props) {
  const { component } = props;

  return (
    <BootstrapDialog
      onClose={() => handleClose(props)}
      aria-labelledby="customized-dialog-title"
      open={component.getData("default.opened", false)}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle className="dialog-title">
        {component.ucfirst("terms-of-use-title")}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(props)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: component.ucfirst("terms-of-use-content"),
          }}
        />
      </DialogContent>
    </BootstrapDialog>
  );
}
