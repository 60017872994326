import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Message1(props) {
  const { component } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={component.getData("default.opened", false)}
      autoHideDuration={4000}
      onClose={() => component.close()}
    >
      <Alert
        severity={component.getData("default.severity", "success")}
        sx={{ width: "100%" }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={() => component.close()}
          >
            <CloseIcon />
          </IconButton>
        }
      >
        {component.getData("default.message", "")}
      </Alert>
    </Snackbar>
  );
}
