import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(2, 2),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0, 2, 2, 2),
  },
  "& .MuiAlert-standard": {
    marginBottom: "10px",
  },
}));

const OrText = styled(Box)(({ theme }) => ({
  "&": {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    ".line": {
      position: "absolute",
      left: 0,
      border: `1px solid ${theme.palette.color5.main};`,
      width: "100%",
    },
    ".text": {
      position: "absolute",
      fontSize: "1.2rem",
      background: "white",
      padding: "0 10px",
    },
  },
}));

const handleClose = (props) => {
  const { component } = props;
  component.closeDialog();
};

export default function StartOrder(props) {
  const { component } = props;

  return (
    <BootstrapDialog
      onClose={() => handleClose(props)}
      aria-labelledby="customized-dialog-title"
      open={component.getData("default.opened", false)}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle className="dialog-title">
        {component.ucfirst("select-type")}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(props)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Button
          type="submit"
          variant="contained"
          color="color1"
          size="large"
          fullWidth
          onClick={() => component.openAddressesDialog()}
        >
          {component.ucfirst("delivery")}
        </Button>
        <OrText>
          <Box className="line"></Box>
          <Box className="text">{component.trans("or")}</Box>
        </OrText>
        <Button
          variant="contained"
          color="color3"
          fullWidth
          size="large"
          onClick={() => component.openSpotsDialog()}
        >
          {component.ucfirst("take-away")}
        </Button>
      </DialogContent>
    </BootstrapDialog>
  );
}
