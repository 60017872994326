import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Header1 from "../../../Components/Widget/Header/Header1";
import Footer1 from "../widget/footer/footer1";

const Page = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.color2.main,
  position: "relative",
  minHeight: "100vh",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  color: "white",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  ".logo": {
    color: "white",
  },
  ".login-btn": {
    color: "white",
  },
  i: {
    color: "white",
  },
}));

const MainContainer = styled(Box)(({ theme }) => ({
  //
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  height: "43px",
  width: "100vw",
  position: "fixed",
  bottom: 0,
  color: "white",
  a: {
    color: "white",
  },
}));

const Section1 = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.color1.main,
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&": {
    ".container": {
      textAlign: "center",
      ".logo": {
        maxWidth: "150px",
        marginBottom: "20px",
      },
      ".button": {
        fontSize: "0.9rem",
        backgroundColor: "white",
        color: "black",
        padding: "10px 40px",
        borderRadius: "50rem",
      },
      ".main-slogun": {
        fontSize: "2.1rem",
        lineHeight: "2.3rem",
        color: "white",
        marginBottom: "15px",
      },
      ".main-slogun-sub": {
        color: "white",
        lineHeight: "1.5rem",
        marginBottom: "20px",
      },
    },
  },
}));

export default function Home(props) {
  const { component } = props;

  return (
    <Page>
      <HeaderContainer position="fixed" component="header">
        <Header1 {...props} />
      </HeaderContainer>
      <MainContainer component="main">
        <Section1>
          <Box className="container">
            <Box>
              <img className="logo" alt="logo" src="/images/logo.svg" />
            </Box>
            <Box className="main-slogun">
              <div
                dangerouslySetInnerHTML={{
                  __html: component.ucfirst("home-main-slogan"),
                }}
              />
            </Box>
            <Box className="main-slogun-sub">
              <div
                dangerouslySetInnerHTML={{
                  __html: component.ucfirst("home-main-slogan-sub"),
                }}
              />
            </Box>
            <Button
              component={Link}
              className="button"
              disableElevation
              variant="contained"
              to="/spot"
            >
              {component.ucfirst("show-menu")}
            </Button>
          </Box>
        </Section1>
      </MainContainer>
      <FooterContainer component="footer">
        <Footer1 {...props} />
      </FooterContainer>
    </Page>
  );
}
