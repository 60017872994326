import { Component } from "../../../../../modules/Core/Component";
import templateDefault from "../../../../templates/default/widget/header/menu/address1";

export default class PromptMessage extends Component {
  template = templateDefault;
  id = "page-header-menu-prompt-message";
  group = "header-message";

  setPromptMessage(promptMessage) {
    this.setData({ "default.promptMessage": promptMessage });
    return this;
  }
}
