import { Link } from "react-router-dom";
import {
  Grid,
  Alert,
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Meal from "./meals/meal";

const Contents = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 0, 0, 0),
  ".menu-meal-type": {
    marginBottom: "50px",
    ".menu-meal-type-title": {
      textAlign: "center",
      marginBottom: "20px",
    },
    ":last-child": {
      marginBottom: "0",
    },
  },
}));

const CircularProgressContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
}));

const ContinueButtonContainerFull = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "0",
  right: "0",
  zIndex: "1000",
  width: "100%",
}));

const ContinueButtonFull = styled(Button)(({ theme }) => ({
  fontSize: "1.3rem",
  width: "100%",
  borderRadius: "0",
}));

export default function Meals(props) {
  const { component } = props;
  const mealTypes = component.getData("default.mealTypes", []);
  const mealsAmount = component.getData("default.mealsAmount");
  const total = component.getPage().getOrderTotal();
  const amount = component.getDateAmount();
  const closed =
    mealsAmount !== null &&
    mealsAmount > 0 &&
    amount === 0 &&
    !component.isDateOpened();

  return (
    <Contents>
      {closed && (
        <Alert
          variant="filled"
          severity="warning"
          sx={{ mb: 2, justifyContent: "center" }}
        >
          {component.ucfirst("spot-is-opened-from")}:{component.getDateNow()}
        </Alert>
      )}
      {mealsAmount !== null ? (
        mealsAmount > 0 ? (
          <Box className="menu-meal-types">
            {mealTypes.map((mealType) => {
              let { slug, name, meals = [] } = mealType;

              meals = meals.filter(({ active, visible }) => {
                return active || visible;
              });

              return (
                meals.length > 0 && (
                  <Box
                    key={`menu-meal-type-${slug}`}
                    className="menu-meal-type"
                  >
                    <Typography
                      className="menu-meal-type-title"
                      variant="h3"
                      sx={{ textAlign: "center", marginBottom: "10px" }}
                    >
                      {component.ucfirst(`${name}s`)}
                    </Typography>
                    <Grid container spacing={2}>
                      {meals.map((meal, index) => {
                        const { _id } = meal;

                        return (
                          <Grid
                            key={`menu-meal-${_id}-${index}`}
                            item
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                          >
                            <Meal
                              {...{ ...props, ...{ meal, mealType, index } }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                )
              );
            })}
          </Box>
        ) : (
          <Alert variant="filled" severity="info">
            {component.ucfirst("no-meals-available")}
          </Alert>
        )
      ) : (
        <CircularProgressContainer>
          <CircularProgress color="color1" />
        </CircularProgressContainer>
      )}
      {total > 0 && (
        <>
          <ContinueButtonContainerFull
            sx={{
              display: {
                // xs: "block",
                sm: "block",
                md: "none",
                // lg: "none",
                // xl: "none",
              },
            }}
          >
            <ContinueButtonFull
              component={Link}
              to="/summary"
              variant="contained"
              size="large"
              color="color4"
              startIcon={<ShoppingCartIcon />}
            >
              {component.ucfirst("continue")}
            </ContinueButtonFull>
          </ContinueButtonContainerFull>
        </>
      )}
    </Contents>
  );
}
